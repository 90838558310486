import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import Home from '../views/TreeHome.vue'
import UserHomePage from '../views/userHomePage.vue'
import AdminHomePage from '../views/adminHomePage.vue'
import RequestAccess from '../views/RequestAccess.vue'
import SignedUpCompliments from '../views/SignedUpCompliment.vue'
import UserLogin from '../views/Login.vue'
import AddNewTree from '../views/addNewTree.vue'
import ModTree from '../views/modTree.vue'
import ModTreeDraft from '../views/modTreeDraft.vue'
import TreeMapView from '../views/treeMapView.vue'
import CameraView from '../views/cameraView.vue'
import UserCheck from '../views/userConfirmation.vue'
import PwdRst from '../views/passwordReset.vue'
import superAdmin from '../views/superAdmin.vue'
import ReqChangePwdMessage from '../views/ReqChangePwdMessage.vue'
import MgmDb from '../views/MgmDb.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/mgmDb',
    name: 'MgmDb',
    component: MgmDb
  },  
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/reqAcc',
    name: 'ReqAcc',
    component: RequestAccess
  },  
  {
    path: '/reqChangePwdMessage',
    name: 'ReqChangePwdMessage',
    component: ReqChangePwdMessage
  },    
  {
    path: '/signedUpCompliments',
    name: 'SignedUpCompliments',
    component: SignedUpCompliments
  },   
  {
    path: '/userLogin',
    name: 'UserLogin',
    component: UserLogin
  },   
  {
    path: '/userHP',
    name: 'UserHomePage',
    component: UserHomePage
  },  
  {
    path: '/addNewTree',
    name: 'AddNewTree',
    component: AddNewTree
  },    
  {
    path: '/modTree',
    name: 'ModTree',
    component: ModTree
  }, 
  {
    path: '/treeMapView',
    name: 'TreeMapView',
    component: TreeMapView
  },  
  {
    path: '/camera',
    name: 'CameraView',
    component: CameraView
  },  
  {
    path: '/modTreeDraft',
    name: 'ModTreeDraft',
    component: ModTreeDraft
  },
  {
    path: '/userCheck',
    name: 'UserCheck',
    component: UserCheck
  }, 
  {
    path: '/pwdRst',
    name: 'PwdRst',
    component: PwdRst
  }, 
  {
    path: '/superAdminHP',
    name: 'SuperAdminHP',
    component: superAdmin
  },       
  {
    path: '/adminHP',
    name: 'AdminHomePage',
    component: AdminHomePage
  },   /*
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  }
  */
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
