<template>
  <v-card
    class="mx-auto ml-4"
    height="270px"
    width="230"
    max-width="344px"
    elevation="3"
    @click="treeDetail"
  >

    <v-img
      :src="tree.photoBlob"
      height="140"
      aspect-ratio="1"
      class="mx-0"
    ></v-img>
      <v-card-text>
        <span @click="viewTree" class="greenColor font-weight-bold ml-0 mt-6">{{tree.treeName}} </span>
        <br>
        <span @click="viewTree" class="greenColor text-subtitle-3 mt-4">Last tree dimensions: <br>{{tree.treeHeight}}m {{tree.treeWidth}}cm </span><br>
        <span @click="viewTree" class="greenColor ml-0 mt-6">Owner:{{tree.owner}} </span>

      <v-row>
        <v-col cols="4">
          <v-btn
            icon
            x-small
            color="#215356"
            class="ml-0"
            rounded
            @click="downloadLocally(tree)"
          >
          <v-icon>
          {{(tree.publicTree) ? "" : ((!tree.localTree) ? ((tree.fkApprovalStatus=='3') ? "mdi-arrow-collapse-down" : "" ): "mdi-clipboard-arrow-down")}}
          </v-icon>  
          </v-btn>          
        </v-col>
        <v-col cols="8">
          <!--<v-icon small color="green">{{cardStatus(tree.fkApprovalStatus)}}</v-icon>-->
          <v-icon x-small :color="statusBall">mdi-checkbox-blank-circle</v-icon>
          <span class="littleText ml-0 mt-6 text--center" :style="statusTextColor">{{cardStatusText(tree.fkApprovalStatus)}}</span>
        </v-col>        
      </v-row>
      </v-card-text>

  </v-card>
</template>
<style scoped>
.scroll {
   overflow-y: scroll
}
.greenColor{
  color:#215356
}
.littleText{
  font-size: x-small;
}
</style>
<script>
import { serverBus } from '../main';
export default {
    name: 'cardAdminTreesPreview',
    props:['tree'],
    components: {
      
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
    },    
    mounted(){
 
    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        db:null,
        dbName:null,
        dbPath:null,
        treez:[],
        statusBall:"green",
        statusTextColor:"color:green"        
    }),
    methods: {
      setColors(color){
        this.statusBall = color
        this.statusTextColor = "color:"+color
      },      
      cardStatusText(status){
        console.log("approvazzo:"+status)
        if (status===undefined) {
          this.setColors("black")
          return " Local"
        }
//,[fkApprovalStatus]   1=bozza 2=inviato 3=approvato 4=non approvato 5=scaricato locale per aggiunta misure        
        switch(status){
          case("2"):
            this.setColors("orange")
            return " Awaiting approval"
          break;
          case("3"):
            this.setColors("green")
            return " Approved"
          break;          
          case("4"):
            this.setColors("red")
            return " Rejected"
          break;        
          case("5"):
            this.setColors("black")
            return " Local"
          break;              
        }
      },      
      cardStatus(status){
//,[fkApprovalStatus]   1=bozza 2=inviato 3=approvato 4=non approvato 5=scaricato locale per aggiunta misure        
        switch(status){
          case("2"):
            return "mdi-dots-horizontal-circle-outline"
          break;
          case("3"):
            return "mdi-emoticon-happy-outline"
          break;          
          case("4"):
            return "mdi-emoticon-sad-outline"
          break;        
          case("5"):
            return "mdi-arrow-down-bold-circle-outline"
          break;              
        }
      },       
        treeDetail(){
            serverBus.$emit('treeDetails',this.tree)
        },
      gotoToPage(page){
        if (page!==this.$route.path) this.$router.push(page);
      },      
      viewTree(){
        //
        console.log(this.tree)
        if (this.tree.localTree){ //local Tree
          console.log("locale")
          setTimeout(() => {
            serverBus.$emit(this.dbName,this.tree);
          }, 400);
          
          this.gotoToPage(this.dbPath)
          
        } else { //remote Tree
          console.log("remoto")

        }


      }

    },
    watch: {

    },    
}
</script>