import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
//import "leaflet/dist/leaflet.css"


import './common/commonFunctions'


export const serverBus = new Vue({
  //
});

console.log("Developed by Gianluca Scandamarro")
//if (process.env.NODE_ENV!=="development") console.log = function() {
  //
//};


Vue.config.productionTip = false

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
