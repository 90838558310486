<template>
    <div
    class="mb-6"
    >
      <v-card-text>
        <v-col>
            <v-row>
            <v-card 
            elevation="0"
            color="gray"
            >
                <v-row>
                    <span
                    class="ma-3"
                    >
                        <v-icon color="#215356" @click="backToTree">mdi-chevron-left</v-icon>
                    </span>  
                </v-row>    
            </v-card>
            </v-row>   
            <v-row>
            <v-card 
            elevation="0"
            >
                <v-row>
                    <span
                    class="littleLittleFonts ma-6"
                    >
                        <span class="font-weight-bold fontGreen">
                        {{(treeData.commonName) ? treeData.commonName : treeData.scientificName}}   
                        </span>
                        <p class="fontGreen">
                        {{treeData.scientificName}}
                        </p>   
                    </span>  
                </v-row>    
            </v-card>
            </v-row>
            <v-row>
              <v-col>
                <v-img
                    class="d-flex justify-center ml-1 mr-1 "
                    :src="'data:image/png;base64,'+treeData.mainImageFilePath_imgL"
                />
              </v-col>
            </v-row>             
            <v-row>
                <v-divider class="mt-2"/>
            </v-row>
            <v-row>
              <v-col>
              <span
              class="littleLittleFonts ma-0"
              >
                  <span class="font-weight-bold fontGreen">
                  Description   
                  </span>
                  <p 
                    class="fontGreen mt-6"
                    v-bind:class="{gradientText:isGradientText}"
                  >
                    {{treeData.description_EN}}
                  </p> 
                  <div v-show="showMore"> 
                  <span class="d-flex justify-center ml-1 fontGreen font-weight-bold">
                    Read More
                  </span>  
                  <p class="d-flex justify-center ml-1 fontGreen font-weight-bold">
                    <v-icon dense color="#215356" @click="expandText"> mdi-chevron-down</v-icon>
                  </p>    
                  </div>  
                  <div v-show="showLess"> 
                  <span class="d-flex justify-center ml-1 fontGreen font-weight-bold">
                    Read Less
                  </span>  
                  <p class="d-flex justify-center ml-1 fontGreen font-weight-bold">
                    <v-icon dense color="#215356"> mdi-chevron-up</v-icon>
                  </p>    
                  </div>                                                 
              </span> 
              </v-col>
            </v-row>
            <v-row>
                <v-divider class="mt-2 mb-2"/>
            </v-row>
            <v-row>
              <v-col>
              <span class="font-weight-bold fontGreen littleLittleFonts">
              Uses   
              </span>
              <div>
                <v-row class="mt-2">
                  <v-col cols="6">
                    <v-icon dense color="#215356">mdi-hammer-screwdriver</v-icon>
                    <span class="font-weight-bold fontGreen littleLittleFonts ml-1">
                    Timber   
                    </span>                    
                  </v-col>
                  <v-col cols="6">
                    <v-icon dense color="#215356">mdi-fire</v-icon>      
                    <span class="font-weight-bold fontGreen littleLittleFonts ml-1">
                    Fuel   
                    </span>                      
                  </v-col>     
                </v-row>  
                <v-row>
                  <v-col cols="6">
                    <v-icon dense color="#215356">mdi-leaf</v-icon>
                    <span class="font-weight-bold fontGreen littleLittleFonts ml-1">
                    Human Food   
                    </span>                      
                  </v-col>
                  <v-col cols="6">
                    <v-icon dense color="#215356">mdi-medical-bag</v-icon>    
                    <span class="font-weight-bold fontGreen littleLittleFonts ml-1">
                    Medicinal   
                    </span>                              
                  </v-col>     
                </v-row>                         
              </div>              
              </v-col>
            </v-row>
            <v-row>
                <v-divider class="mt-2 mb-2"/>
            </v-row>   
            <v-row>
              <v-col>
              <span class="font-weight-bold fontGreen littleLittleFonts">
              Potential Carbon Benefits   
              </span>
              <div>
                <v-row class="mt-2">
                  <v-col>
                  <p 
                    class="fontGreen littleLittleFonts"
                  >
                    When this tree reaches: 

                  </p>     
                  <p 
                    class="fontGreen littleLittleFonts mt-4"
                  >
                    <b>{{treeData.carbonBenefitsReferenceHeight}}</b> in height 
                  </p>   
                  <p 
                    class="fontGreen littleLittleFonts mt-1"
                  >
                    <b>{{treeData.carbonBenefitsReferenceDiameter}}</b> in diameter 
                  </p>    
                  <p 
                    class="fontGreen littleLittleFonts mt-3"
                  >
                    Will capture <b>{{treeData.carbonBenefitsCO2Captured}} tCO2</b> 
                  </p>                                                              
                  </v-col>        
                </v-row>                         
              </div>              
              </v-col>
            </v-row>    
            <v-row>
                <v-divider class="mt-2 mb-2"/>
            </v-row>  
            <v-row>
              <v-col>
              <span class="font-weight-bold fontGreen littleLittleFonts">
              Biodiversity Benefits   
              </span>
              <div>
                <v-row class="mt-2">
                  <v-col>
                  <p 
                    class="fontGreen littleLittleFonts"
                  >
                    This species is cosidered by the IUCN.

                  </p>     
                                                         
                  </v-col>        
                </v-row>                         
              </div>              
              </v-col>
            </v-row>    
            <v-row>
                <v-divider class="mt-2 mb-2"/>
            </v-row>  
            <v-row>
              <v-col>
              <span class="font-weight-bold fontGreen littleLittleFonts">
              Distribution  
              </span>
              <div>
                <v-row class="mt-2">
                  <v-col>
              <v-card
              height="200px"

              >                    
                <tree-map-view-preview-tree-story
                  v-bind:coordCenter="coordCenterPreview"
                  v-bind:coordPoly="coordPolyPreview"
                />  
              </v-card>                        
                  </v-col>        
                </v-row>                         
              </div>              
              </v-col>
            </v-row>                                                    
        </v-col>
      </v-card-text>
    </div>
</template>
<style scoped>
.littleLittleFonts{
    font-size: 10px;
    line-height: 1;
    font-weight: normal;
    margin: 0;
    padding: 0;
}
.bordersBottom {
    background-color: #ffffff !important;
    border-bottom: 1px solid #215356 !important
}
.fontGreen{
    color:#215356 
}
.borders {
    background-color: #ffffff !important;
    border: 1px solid #215356 !important;
    border-radius: 15px;
    
}
.gradientText {
  height: 40px;
  background: -webkit-linear-gradient(#215356, #eee);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
<script>
import treeMapViewPreviewTreeStory from '../components/treeMapViewPreviewTreeStory.vue'
import { serverBus } from '../main';
export default {
    name: 'treeStory',
    props:['treeData'],
    components: {
      treeMapViewPreviewTreeStory
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
    },    
    mounted(){
      //dopo il mount
      serverBus.$emit("updateMapPointPreviewTreeStory",this.coordCenterPreview,this.polygon.latlngs);
    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        showMore:true,
        showLess:false,

        //CSS Magic
        isGradientText:true,
        coordCenterPreview:[45.480938300000005,10],
        coordPolyPreview:[45.480938300000005,10],
    }),
    methods: {
      expandText(){
        this.isGradientText = false
        this.showMore = false
      },
      backToTree(){
        serverBus.$emit("backToMain");
      }

    },
    watch: {

    },    
}
</script>