<template>
<div>
    <v-container class="d-flex justify-center mt-0">
    <v-img
      src="../assets/appTitle1.png"
      height="100px"
      contain
    />
    </v-container>   
    <v-container class="d-flex justify-center mt-0">
    <v-img
      src="../assets/envelope.svg"
      height="180px"
      contain
    />
    </v-container> 
    <v-card class="d-flex justify-center mt-0" elevation="0">
        <h3 class="greenColor text-center">You asked to reset your password!</h3>
</v-card> 
<v-card class="ml-5" elevation="0">
        <p class="greenColor text-center ml-12 mr-12">We've sent you an email to validate your request!</p>
</v-card>       
  
</div>
</template>
<style scoped>
.scroll {
   overflow-y: scroll
}
.greenColor{
  color:#215356
}
</style>
<script>
export default {
    name: 'SignedUpCompliment',
    props:[''],
    components: {
      
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
    },    
    mounted(){
      //dopo il mount
    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        
    }),
    methods: {

    },
    watch: {

    },    
}
</script>