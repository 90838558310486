<template>
<div>
    <v-container class="mt-0" v-show="screenA==='home'">
      <v-card
      elevation="0"
      height="300"
      >
        <span class="subtitle-2 bold greenColor">All Trees</span>
        <span class="caption greenColor float-right" @click="seeAllValidateTrees">See all</span>
        <v-slide-group
        v-model="sliderMyTrees"
        class="pa-0"
        active-class="success"
        >
            <v-slide-item
                v-for="tree in validateTrees"
                :key="tree.id"
            >
                <card-admin-tree-preview
                v-bind:tree="tree"
                class="mb-3 mt-3"
                />
            </v-slide-item>
        </v-slide-group>
      </v-card>
      <v-card
        elevation="0"
        height="200px"
        class="mt-6">
        <span class="subtitle-2 bold greenColor">New Users</span>
        <span class="caption greenColor float-right" @click="seeAllNewUsers">See all</span>
        <v-slide-group
        v-model="sliderMyTrees"
        class="pa-0"
        active-class="success"
        >
            <v-slide-item
                v-for="user in newUsers"
                :key="user.id"
            >
                <user-card
                v-bind:userData="user"
                />
            </v-slide-item>
        </v-slide-group>
      </v-card>
      <v-card
        elevation="0"
        height="200px"
        class="mt-6">
        <span class="subtitle-2 bold greenColor">Existing Users</span>
        <span class="caption greenColor float-right" @click="seeAllExistingUsers">See all</span>
        <v-slide-group
        v-model="sliderMyTrees"
        class="pa-0"
        active-class="success"
        >
            <v-slide-item
                v-for="user in allUsers"
                :key="user.id"
            >
                <user-card
                  v-bind:userData="user"
                />
            </v-slide-item>
        </v-slide-group>
      </v-card>
    </v-container>
    <v-container class="mt-0" v-show="screenA==='treeDetail'">
      
      <v-icon @click="screenA=screenBefore">mdi-close</v-icon>
      <v-btn
        fab
        dark
        small
        fixed
        bottom
        left
        color="#215356"
        @click="treeActions('approved')" 
        class="mt-12"
        
      >
        <v-icon>mdi-check-bold</v-icon> 
      </v-btn>    
      <v-btn
        fab
        dark
        small
        fixed
        bottom
        right
        color="error"
        @click="treeActions('rejected')"
        class="mt-12"
        
      >
        <v-icon>mdi-close</v-icon> 
      </v-btn>           
      <tree-details
        v-bind:treeData="detailsData"
      
      />
    </v-container>
    <v-container class="mt-0" v-show="screenA==='allValidateTrees'">
      <v-icon @click="screenA=screenBefore">mdi-close</v-icon>
      <v-card
          v-for="tree in validateTrees"
          :key="tree.id"
          class="mb-6"
      >
          <card-admin-tree-preview
          v-bind:tree="tree"
          />
      </v-card>
    </v-container>
 
    <v-container class="mt-0" v-show="screenA==='seeAllNewUsers'">
      <v-icon @click="screenA=screenBefore">mdi-close</v-icon>
      <v-card
          v-for="user in newUsers"
          :key="user.id"
          elevation="0"
          class="mb-6 ml-12"
      >
        <user-card
        v-bind:userData="user"
        />
      </v-card>      
    </v-container>     
    <v-container class="mt-0" v-show="screenA==='seeAllExistingUsers'">
      <v-icon @click="screenA=screenBefore">mdi-close</v-icon>
      <v-card
          v-for="user in allUsers"
          :key="user.id"
          elevation="0"
          class="mb-6 ml-12"
      >
        <user-card
        v-bind:userData="user"
        />
      </v-card>      
    </v-container>         
</div>
</template>
<style scoped>
.scrollo {
  overflow: scroll;
    overflow-y: scroll;
}
.greenColor{
  color:#215356
}
</style>
<script>
import cardAdminTreePreview from '../components/cardAdminTreesPreview.vue'
import treeDetails from '../components/treeDetails.vue'
import userCard from '../components/userCard.vue'
import { serverBus } from '../main';

export default {
    name: 'userHomePage',
    props:[''],
    components: {
      cardAdminTreePreview,treeDetails,userCard,

    },
    beforeCreate(){
      /*
      serverBus.$on("updateLocalTree",(data) =>{
        console.log(data)
        this.localTrees.push(data)
      }); 
    
      serverBus.$on("updateLocalTreeDraft",(data) =>{
        console.log(data)
        this.localTreesDraft.push(data)
      }); /*
      serverBus.$on("dialogModTree",(stat) =>{

      }); */
      serverBus.$on("updateAdminTrees",(data) =>{
        console.log(data)
        this.validateTrees.push(data)
      });        
      serverBus.$on("treeDetails",(data) =>{
        this.detailsData = data
        this.changeScreen("treeDetail")
        setTimeout(() => {
          serverBus.$emit("viewSingleTree");
        }, 200);
      });       
      serverBus.$on("updateUserStatus",() =>{
       this.getExistingUsers()
       this.getNewUsers()
      });           
    },
    mounted(){
      //dopo il mount
      /*
      this.createLocalDb('treeCache')
      setTimeout(() => {
        this.updateMyTreeUpdateView('treeCache',"updateLocalTree")
      }, 500);
      this.createLocalDb('treeCacheDraft')
      setTimeout(() => {
        this.updateMyTreeUpdateView('treeCacheDraft',"updateLocalTreeDraft")
      }, 500);      
      if (this.$store.state.OnLine){    
        setTimeout(() => {
            this.getRemoteTrees()
            this.getPublicTrees()
        }, 1000);}

        setTimeout(() => {
            console.log("utente:"+this.$store.state.userData.firstName)
        }, 3000);
        */
       this.screenA = "home"
       this.getExistingUsers()
       this.getNewUsers()
       this.getAllOrgTrees()
    },    
    data:()=> ({
      alignments: [
        'start',
        'center',
        'end',
      ],  
      sliderMyTrees: null,
      sliderMyDraft: null,

      //alberi salvati locali
      validateTrees:[],
      newUsers:[],
      allUsers:[],

      normale:"normale",
      draft:"draft",

      //l map
      screenA:"home",
      screenBefore:"",

      //tree details
      detailsData:[],


    }),  
    methods: {
      changeScreen(newScreen){
        this.screenBefore = this.screenA
        this.screenA = newScreen
      },
      seeAllValidateTrees(){
//
        this.changeScreen("allValidateTrees")
        //this.getSameOrgTrees()
      },
      seeAllNewUsers(){
//
        this.changeScreen("seeAllNewUsers")
        this.getNewUsers()        
      },
      seeAllExistingUsers(){
//
        this.changeScreen("seeAllExistingUsers")
        this.getNewUsers()  
      },
      treeActions(action){
        console.log(action+" "+this.detailsData.id)
        var treeId = this.detailsData.id
        var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
        method: 'post',
        url: 'trees/changeTreeStatus',
        data: {
          tok:locSto,
          action:action,
          treeId:treeId
        }
        }).then((response) => {     
            console.log(response.data)

            this.changeScreen("home")
            this.getSameOrgTrees()
        });             
        } ,      
      backHome(){
        
        this.screenA='home'
        this.detailsData=[]
      },
      getAllOrgTrees(){
        this.validateTrees = []
        var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
        method: 'post',
        url: 'trees/treeListForSuperAdmin',
        data: {
          tok:locSto,
          //tree: data,
          activated:false,
          all:true
        }
        }).then((response) => {     
            console.log("alberelli per admin")
            console.log(response.data)
            if (response.data){
                response.data.forEach(element => {
                    console.log(element)
                    var data = {treeName:element.recordName,
                                fkApprovalStatus:element.fkApprovalStatus,
                                photoBlob:element.imageFilePath_imgL,
                                realBlob:null,
                                localTree:false,
                                owner:element.owner,
                                syncId:null,
                                plantationDate:element.operationDateTime,
                                selectedTreeType:element.fkTreeTypeList,
                                treeHeight:element.height_m,
                                treeWidth:element.diameter_cm,
                                id:element.id
                                }
                    serverBus.$emit("updateAdminTrees",data);
                });
            }       
        });             
    },        
      getExistingUsers(){
        var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
        method: 'post',
        url: 'mgme/userListSuperAdmin',
        data: {
          tok:locSto,
          //tree: data,
          activated:true,
          all:false
        }
        }).then((response) => {     

            console.log("utenti da admin")
            console.log(response.data)
            this.allUsers = response.data
        });             
    },  
      getNewUsers(){
        var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
        method: 'post',
        url: 'mgme/userListSuperAdmin',
        data: {
          tok:locSto,
          //tree: data,
          activated:false,
          all:false
        }
        }).then((response) => {     

            console.log("utenti da admin")
            console.log(response.data)
            this.newUsers = response.data
        });             
    },             
      getPublicTrees(){
        var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
        method: 'post',
        url: 'trees/PublicTrees',
        data: {
          tok:locSto,
          //tree: data,
        }
        }).then((response) => {     

            console.log("alberi remoti pubblici")
            console.log(response.data)
          response.data.forEach(element => {
            console.log(element)
            var data = {treeName:element.recordName,
                        fkApprovalStatus:element.fkApprovalStatus,
                        photoBlob:element.imageFilePath_imgL,
                        realBlob:null,
                        localTree:false,
                        owner:element.owner,
                        syncId:null,
                        plantationDate:element.operationDateTime,
                        selectedTreeType:element.fkTreeTypeList,
                        treeHeight:element.height_m,
                        treeWidth:element.diameter_cm,
                        id:element.id
                        }
            serverBus.$emit("updatePublicTrees",data);
        });
        });             
    },        
      getRemoteTrees(){
        var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
        method: 'post',
        url: 'trees/treeList',
        data: {
          tok:locSto,
          //tree: data,
        }
        }).then((response) => {     

            console.log("alberi remoti")
            console.log(response.data)
          response.data.forEach(element => {
            console.log(element)
            var data = {treeName:element.recordName,
                        fkApprovalStatus:element.fkApprovalStatus,
                        photoBlob:element.imageFilePath_imgL,
                        realBlob:null,
                        localTree:false,
                        owner:element.owner,
                        syncId:null,
                        plantationDate:element.operationDateTime,
                        selectedTreeType:element.fkTreeTypeList,
                        treeHeight:element.height_m,
                        treeWidth:element.diameter_cm,
                        id:element.id
                        }
            serverBus.$emit("updateLocalTree",data);
        });
        });             
    },
    createLocalDb(dbName){
    let request = window.indexedDB.open(dbName, 1);
    request.onerror = function() {
        alert('Database failed to open');
    };
    // onsuccess handler signifies that the database opened successfully
    request.onsuccess = function() {
        console.log('Database opened successfully');

        // Store the opened database object in the db variable. This is used a lot below
        //var db = request.result;

        
        // Run the displayData() function to display the notes already in the IDB
        //displayData();
    };     
    
    // Setup the database tables if this has not already been done
    request.onupgradeneeded = function(e) {
        // Grab a reference to the opened database
        let db = e.target.result;

        // Create an objectStore to store our notes in (basically like a single table)
        // including a auto-incrementing key
        let objectStore = db.createObjectStore(dbName, { keyPath: 'id', autoIncrement:true });

        // Define what data items the objectStore will contain
        objectStore.createIndex('treeName', 'treeName', { unique: false });

        console.log('Database setup complete');
    };        
    },   
    updateMyTreeUpdateView(dbName,emitRelativo){
        let request = window.indexedDB.open(dbName, 1);
        request.onerror = function() {
            console.log('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
            console.log('Database opened successfully');

            // Store the opened database object in the db variable. This is used a lot below
            var db = request.result;

            console.log("result")
            console.log(db)

            // open a read/write db transaction, ready for adding the data
            let transaction = db.transaction([dbName]);
            // call an object store that's already been added to the database
            let objectStore = transaction.objectStore(dbName);  
            
            objectStore.openCursor().onsuccess = function(event) {
            var cursor = event.target.result;
            if (cursor) {
                var photoHtml = (cursor.value.photoBlob!==null) ? window.URL.createObjectURL(cursor.value.photoBlob) : null
                var data = {  treeName:cursor.value.treeName,
                            photoBlob:photoHtml,
                            realBlob:cursor.value.photoBlob,
                            localTree:true,
                            owner:cursor.value.treeOwner,
                            syncId:cursor.value.syncId,
                            plantationDate:cursor.value.plantationDate,
                            selectedTreeType:cursor.value.selectedTreeType,
                            treeHeight:cursor.value.treeHeight,
                            treeWidth:cursor.value.treeWidth,
                            id:cursor.value.id
                            }
                console.log("cursor")
                console.log(cursor)
                serverBus.$emit(emitRelativo,data);
                cursor.continue();
            }
            else {
                console.log("finito alberi locali");
            }
            };
            
            // Make a request to add our newItem object to the object store
            let request2 = objectStore.get("");



            request2.onsuccess = function(event) {
            // Clear the form, ready for adding the next entry
            console.log('Richiesta recupero OK.');
            console.log(event)
            };

            // Report on the success of the transaction completing, when everything is done
            transaction.oncomplete = function() {

            // update the display of data to show the newly added item, by running displayData() again.
            //displayData();
            };

            transaction.onerror = function() {
            console.log('Recupero fallito');
            };   
        };  
    }, 
    testGET(){
        this.axios({
        method: 'get',
        url: 'auth/test'
        }).then((response) => {

            console.log(response.data)
        });
    },
    addNewTree(){
    this.gotoToPage("/addNewTree")
    },
    subMenuAlbero(){
    //
    },
    gotoToPage(page){
    if (page!==this.$route.path) this.$router.push(page);
    },
    addTree(){
    this.gotoToPage("/addNT1")
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    }  


    },

}


</script>