<template>
  <v-app>
    <v-app-bar
      app
      color="#FFFFF0"
      dark
      dense
      v-show="$route.path==='/reqAcc'"
      fixed
      elevation="0"
    >
      <v-app-bar-nav-icon
        color="#215356"
        @click="goUser"
        v-show="($store.state.logged)"
      ><v-icon>mdi-chevron-left</v-icon></v-app-bar-nav-icon>
      <v-toolbar-title class="greenColor">
        Sign up
      </v-toolbar-title>
    </v-app-bar>
    <v-app-bar
      app
      color="#FFFFF0"
      dark
      dense
      v-show="($store.state.logged)"
      fixed
      elevation="0"
    >
      <v-app-bar-nav-icon
      @click="homeP"
      :color="($store.state.OnLine) ? '#215356' :'error'"
      />
      
      <v-toolbar-title class="greenColor subtitle-1">{{$store.state.userData.firstName}}'s {{pageTitle()}}</v-toolbar-title>
      
      <v-spacer/>
    <v-progress-circular
      size="20"
      width="1"
      color="#215356"
      class="float-right"
      v-show="waitingOnline"
      indeterminate    
    />
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      dense
      app
      fixed
      mobile-breakpoint
      width="128"
      color="#215356"
    >
      <v-list-item 
      v-show="(($store.state.userData.fkRole==='1') || ($store.state.userData.fkRole==='2'))"
      @click="goAdmin">
        <v-list-item-content>
          <v-col><v-row>
          <v-icon x-small>mdi-star</v-icon></v-row>
          <v-row><v-list-item-title >Admin</v-list-item-title></v-row>
          </v-col>
        </v-list-item-content>
      </v-list-item>
      <v-list-item 
      v-show="$store.state.userData.fkRole!=='0'"
      @click="goUser">
        <v-list-item-content>
          <v-list-item-title>User</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="logOff">
        <!--
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>
-->
        <v-list-item-content>
          <v-list-item-title>Log Off</v-list-item-title>
        </v-list-item-content>
      </v-list-item>      
      <v-divider></v-divider>
      <v-list-item 
      v-show="$store.state.userData.fkRole==='2'"
      @click="superAdmin">
        <!--
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
        </v-list-item-avatar>
-->

        <v-list-item-content>
          <v-col><v-row>
          <v-icon x-small>mdi-star</v-icon><v-icon x-small>mdi-star</v-icon></v-row>
          <v-row><v-list-item-title >Admin</v-list-item-title></v-row>
          </v-col>
        </v-list-item-content>
      </v-list-item>   
      <v-list dense>
        <v-list-item
          v-for="item in drawerItems"
          :key="item.title"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>  
    
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
    <main-tasks/>
    <v-footer 
      app 
      v-show="($store.state.logged)"
      fixed
    >
        <span class="caption mt-0">
        &copy;Copyright {{ new Date().getFullYear() }} by ERM.</span>
    </v-footer>
  
  </v-app>
</template>
<style scoped>
.scroll {
   overflow-y: scroll
}
.greenColor{
  color:#215356
}
</style>
<script>
import mainTasks from './common/mainTasks.vue'
import { serverBus } from './main'
export default {
  name: 'App',
  props:[''],
  components: {
    mainTasks
  },
    beforeCreate(){
      serverBus.$on("waitingOnline",(data) =>{
        if ((data>0) && (data<100))
      {          
          this.waitingOnline = true
      } else {
           this.waitingOnline = false
      }
        
      });  
      
  },  
  beforeDestroy(){
    //serverBus.$off('waitingOnline');
  },
  mounted(){
    //dopo il mount
    console.log(this.$route.path);
  },
  data: () => ({
    //
    drawer:false,
    drawerItems:[],
    waitingOnline:false,
    circularVal:0,
    continueCirle:false,

  }),
  methods: {
    goAdmin(){
      this.gotoToPage("/adminHP")
    },
    goUser(){
      this.gotoToPage("/userHP")
    },
    superAdmin(){
      this.gotoToPage("/superAdminHP")
    },    
    logOff(){
      
      localStorage.removeItem('tok');

      setTimeout(() => {
        this.gotoToPage("/userLogin")
      }, 500);
      
    },
    pageTitle(){
      switch(this.$route.path){
        case("/modTree"):
          return "Modify Tree"
        case("/userHP"):
          return "Home Page"  
        case("/addNewTree"):
          return "Add New Tree"   
        case("/modTreeDraft"):
          return "Modify Draft"          
        case("/adminHP"):
          return "Admin HomePage"       
        case("/treeMapView"):
          return "Map"
        case("/superAdminHP"):
          return "Super Admin"                                             
      }
    },
    gotoToPage(page){
      if (page!==this.$route.path) this.$router.push(page);
    },
    homeP(){
      //this.gotoToPage("/userHP")
      this.drawer = !this.drawer
    },
  },  
};
</script>
<!--
Modificare il file node_modules/neo-async/async.js

  function onlyOnce(func) {
    return function(err, res) {
      var fn = func;
      
      func = () => {
        console.error(defined);
        throwError();
      };

      //func = throwError;
      fn(err, res);
    };
  }
  -->

