<template>
<div>
    <v-img
        :src="treeInfo.imageFilePath_imgL"
        aspect-ratio="1.7"
        class="ml-3 mt-6 mr-3"
    ></v-img>
    <v-card
        elevation="0"
        class="mt-4 ml-3 mr-3 mb-12"
    >
      <v-icon color="#215356" dense>mdi-tree</v-icon><span class="caption mt-0 greenColor"> Tree Name</span><br>
      {{treeInfo.recordName}}<br>
      <v-icon color="#215356" dense>mdi-account</v-icon><span class="caption mt-0 greenColor"> Owner Name</span><br>
      {{treeInfo.owner}}<br>
      <v-divider class="mt-2 mb-2"/>
      <v-icon color="#215356" dense>mdi-calendar</v-icon><span class="caption mt-0 greenColor"> Date of plantation</span><br>
      {{formatDate(treeInfo.operationDate)}}<br> 
      <v-divider class="mt-2 mb-2"/> 
      <v-icon color="#215356" dense>mdi-tree</v-icon><span class="caption mt-0 greenColor"> Single Tree</span><br>    
      <v-divider class="mt-2 mb-2"/>
      <v-icon color="#215356" dense>mdi-leaf</v-icon><span class="caption mt-0 greenColor"> Type Of Tree</span><br>
      {{treeInfo.commonName_EN}}<br> 
      <v-divider class="mt-2 mb-2"/>
      <v-icon dense color="#215356">mdi-ruler</v-icon><span class="caption mt-0 greenColor"> Dimensions</span> <br> 
      <v-row>
        <v-col cols="6">
          {{formatDate(treeInfo.operationDate)}}<br> 
        </v-col>
        <v-col cols="6">
          <span class="caption text--bold">{{treeInfo.height_m}} m x {{treeInfo.diameter_cm}} cm</span>
        </v-col>
      </v-row>
      <div 
        v-for="item in singleTreeEntries"
        v-bind:key="item.id"
      >
        <v-divider class="mt-2 mb-2"/>
        <v-row>
          <v-col cols="6">
            {{formatDate(item.operationDateTime)}}<br> 
          </v-col>
          <v-col cols="6">
            <span class="caption text--bold">{{item.height_m}} m x {{item.diameter_cm}} cm</span>
          </v-col>
        </v-row>
      </div>

    <v-divider class="mt-2 mb-2"/>
    </v-card>
</div>
</template>

<script>
import { serverBus } from '../main';

export default {
    name: 'treeDetails',
    props:['treeData'],
    components: {
      
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
      this.treeInfo = []
      serverBus.$on("viewSingleTree",() =>{
        setTimeout(() => {
          if (this.treeData.fkApprovalStatus!=='5'){ 
            this.getTreeInfo()
          } else {
            this.getTreeLocalDataFromRemoteTree()
          }
        }, 400);
        
      });         
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
      
    },    
    mounted(){
      //dopo il mount

    },
    beforeUpdate(){
      //prima dell'aggiornamento
      
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
      //serverBus.$off("viewSingleTree")
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        treeInfo:[],
        singleTreeEntries:[],

    }),
    methods: {
      getTreeLocalDataFromRemoteTree(){
        this.treeInfo = this.treeData 
        this.treeInfo.recordName = this.treeData.treeName
        this.treeInfo.operationDate = this.treeData.plantationDate
        this.treeInfo.height_m = this.treeData.treeHeight
        this.treeInfo.diameter_cm = this.treeData.treeWidth
        this.treeInfo.commonName_EN = this.treeData.commonName_EN


        //richiama i dati salvati offline
        serverBus.$emit("viewDataAddedBefore");


        console.log("commond") 
        console.log(this.treeInfo)    
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split(' ')[0].split('-')
        return `${day}/${month}/${year}`
      },      
      getTreeInfo(){
        this.treeInfo = []
        console.log("getTreeInfo")
        console.log(this.treeData)
        var idTree = this.treeData.id
        setTimeout(() => {
          var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
        method: 'post',
        url: 'trees/getTreeInfo',
        data: {
          tok:locSto,
          treeId:idTree
        }
        }).then((response) => {     

            console.log("visu tree")
            console.log(response.data)
            this.treeInfo = response.data
            setTimeout(() => {
              this.getTreeEntries()
            }, 300);
        });           
        }, 300);
        
          
    },  
      getTreeEntries(){
        this.singleTreeEntries = []
        console.log("getTreeInfo")
        console.log(this.treeData)
        var idTree = this.treeData.id
        setTimeout(() => {
          var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
        method: 'post',
        url: 'trees/getSingleTreeEntries',
        data: {
          tok:locSto,
          treeId:idTree
        }
        }).then((response) => {     

            console.log("visu tree entries")
            console.log(response.data)
            this.singleTreeEntries = response.data
        });           
        }, 300);
      }
    },
    watch: {

    },    
}
</script>