<template>
<v-container >
  <v-row>
    <v-col 
      cols="5"
      sm="4"
      md="4"
      xs="4"
      lg="3"
    />
    <v-col 
    
    cols="12"
    sm="0"
    md="0"
    xs="0"
    lg="4"
    
    >
      <div
        class="pl-3 pr-3 pt-6"
        v-show="partQ===1">
        <v-icon color="#215356" dense>mdi-account</v-icon>
        <span class="caption mt-0"> Name*</span>
        <v-text-field
          v-model="firstname"
          required
          :rules="fieldRules"
          clearable
          dense
          outlined
          color="#215356"
        />    
        <v-icon color="#215356" dense>mdi-email-outline</v-icon>
        <span class="caption mt-0"> E-mail*</span>
        <v-text-field
          v-model="email"
          required
          :rules="emailRules"
          clearable
          outlined
          dense
          color="#215356"      
        />       
        <v-icon color="#215356" dense>mdi-phone-hangup-outline</v-icon>
        <span class="caption mt-0"> Phone*</span>
              <!--
                <VuePhoneNumberInput
                  id="phoneNumber1"
                  v-model="phoneNumber"
                  color="dodgerblue"
                  :dark="dark"
                  :disabled="disabled"
                  :ignored-countries="countriesIgnored"
                  :preferred-countries="countriesList"
                  :loader="hasLoaderActive"
                  clearable
                  :error="hasErrorActive"
                  class="mb-2"
                  @update="onUpdate"
                  size="sm"
                />-->
        <VuePhoneNumberInput
          v-model="numbers"
          color="dodgerblue"
          clearable
          class="mb-2"
          no-example
          no-flags
          no-validator-state
          no-use-browser-locale
          fetch-country
          @update="getPhoneNumber"
          size="sm"
        />      
        <v-icon color="#215356" dense>mdi-barcode</v-icon>
        <span class="caption mt-0"> Organization*</span>
        <v-select
          class="mt-2"
          :items="itemsOrg"
          @change="selectOrg"
          dense
          outlined
          color="#215356"       
        />    
        <div
          class="pt-2 pb-6"
        >
          <v-container class="d-flex justify-center pt-6 pb-8">
            <v-btn
              small
              elevation="2"
              color="#215356"
              class="white--text"
              @click="nextPage"
            >
            Next
            </v-btn>
          </v-container>
        </div>    
      </div>
      <div 
        v-show="partQ===2"
        class="pl-3 pr-3 pt-6"  
      >
        <v-icon color="#215356" dense>mdi-account-outline</v-icon>
        <span class="caption mt-0"> Username*</span>
        <v-text-field
          v-model="username"
          dense
          required
          :rules="fieldRules"
          clearable
          outlined
          color="#215356"      
        />    
        <v-icon color="#215356" dense>mdi-key-outline</v-icon>
        <span class="caption mt-0"> Password*</span>      
        <v-text-field
          class="pt-3"
          v-model="password1"
          :append-icon="pwdShow1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[passwordRules.required, passwordRules.min]"
          :type="pwdShow1 ? 'text' : 'password'"
          hint="At least 8 characters"
          @click:append="pwdShow1 = !pwdShow1"
          required
          clearable
          dense
          outlined
          color="#215356"       
        />
        <v-progress-linear
          :color="score.color"
          :value="score.value"
        />
        <div
          class="pt-2 pb-6"
        >
          <v-container class="d-flex justify-center pt-6 pb-8">
            <v-btn
              small
              elevation="2"
              color="#215356"
              class="white--text"
              @click="partQ=1"
            >
            Prev
            </v-btn>
            <v-btn
              small
              elevation="2"
              color="#215356"
              class="ml-2 white--text"
              @click="reqAccess"
              :disabled="!buttonStatus"
            >
            Sign Up
            </v-btn>
          </v-container>
        </div>
      </div>  
    </v-col>
  </v-row>
    <v-dialog
      v-model="dialogError"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-icon @click="dialogError=false">mdi-close</v-icon>
        <v-card-title>Fields Error</v-card-title>
        <v-card-text>
        
        {{errorTextMessage}}

        </v-card-text>
      </v-card>
    </v-dialog>

</v-container>
</template>
<style scoped>
.scrollo {
  overflow: scroll;
    overflow-y: scroll;
}
.greenColor{
  color:#215356
}
</style>
<script>
 import VuePhoneNumberInput from 'vue-phone-number-input'
import zxcvbn  from 'zxcvbn';
export default {
    name: 'requestAccess',
    props:[''],
    components: { 
      VuePhoneNumberInput
    },
    computed: {    
    score() {
      const result = zxcvbn(this.password1);

      switch (result.score) {
        case 4:
          this.pwdOk = true
          return {
            color: "light-blue",
            value: 100
          };
        case 3:
          this.pwdOk = true
          return {
            color: "light-green",
            value: 75
          };
        case 2:
            this.pwdOk = true
            console.log("peok")
            console.log(this.pwdOk)
          return {
            color: "yellow",
            value: 50
          };
        case 1:
          this.pwdOk = false
          return {
            color: "orange",
            value: 25
          };
        default:
          this.pwdOk = false
          return {
            color: "red",
            value: 0
          };
      }
    }
  },    
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
    },    
    mounted(){
      //dopo il mount
      this.axios.get("auth/orgList").then((response) => {
        this.itemsOrg = response.data
      })
    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        username:null,
        firstname:null,
        middlename:null,
        lastname:null,
        email:null,
        password1:"",
        password2:null,
        phoneNumber:null,
        numbers:null,
        fieldRules: [
            v => !!v || 'Field is required',
        ],  
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],  
        passwordRules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },   
        pwdShow1:false,
        pwdShow2:false, 
        pwdOk:false,
        buttonStatus:false, 
        itemsOrg:[], 
        selectedOrg:null,   
        
        //pagine
        partQ:1,
        dialogError:false,
        errorTextMessage:"",
    }),
    methods: {
      nextPage(){
        if (this.email && this.firstname && this.phoneNumber && this.selectedOrg){
          this.partQ=2
        } else {

          this.errorTextMessage = "Please compile all the required fields"
          this.dialogError = true

        }

        
      },
      getPhoneNumber(val){
/*

{ "countryCode": "US", "isValid": false, "phoneNumber": "(334)3", "countryCallingCode": "1", "formattedNumber": "+13343", "nationalNumber": "3343", "formatInternational": "+1 3343", "formatNational": "3343", "uri": "tel:+13343", "e164": "+13343" }
*/
        this.phoneNumber = val.formatInternational
        console.log(val)
      },
      test(){
        console.log(this.phoneNumber)
      },
        selectOrg(value){
          this.selectedOrg = value
          console.log(value)
        },
        checkForButton(){
            this.buttonStatus = (this.pwdOk && this.username!==null && this.firstname!==null && this.email!==null && this.selectedOrg!==null)
            console.log("pwdok")
            console.log(this.pwdOk)
            console.log("button")
            console.log(this.buttonStatus)
        },
        gotoToPage(page){
            if (page!==this.$route.path) this.$router.push(page);
        },        
        reqAccess(){
           /* this.axios.get("v1/auth/test").then((response) => {
                console.log(response.data)
            }) */ 
            var req = {
                username: this.username,
                firstname: this.firstname,
                email: this.email,
                pwd: this.password1,
                org: this.selectedOrg
            }

            console.log(req)

         /* */ 

            this.axios({
            method: 'post',
            url: 'auth/addUser',
            data: {
                data: req
            }
            }).then((response) => {
                //console.log(response.data)
                console.log(response.data)
                this.gotoToPage("/signedUpCompliments")
            });        
        },

    },
    watch: {
        password1(value){
            //this.pwdOk=(value==this.password2)
            this.checkForButton()
        },/*
        password2(value){
            this.pwdOk=(value==this.password1)
            this.checkForButton()
        },*/
        username(){
            this.checkForButton()
        },
        firstname(){
            this.checkForButton()
        },
        lastname(){
            this.checkForButton()
        },
        email(){
            this.checkForButton()
        }        
    },    
}
</script>