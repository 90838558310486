<template>
<v-container>
    <v-container class="d-flex justify-center mt-0">
      <v-card
      elevation="0">
      <img 
        alt="TreeTraker logo" 
        width="100"
        contain
        src="../assets/logo-green.png">
            </v-card>
    </v-container>
    <v-container class="d-flex justify-center mt-0">
      <img 
        alt="TreeTraker logo2" 
        :width="widthGrafica"
        class="align-self-stretch"
        contain        
        src="../assets/logo-image.png"
        height="150"
        > 
    </v-container>        
    <v-container class="d-flex justify-center mt-0">  
      <p class="text-center text-h6 ">
        <span class="greenColor"> Thank you. <br>  You can now log in and start tracking your tree plantation. </span>
      </p>   
    </v-container>      
    <v-container class="d-flex justify-center mt-0">  
      <v-btn
        small
        elevation="3"
        color="#215356"
        class="white--text"
        @click="login"
      >
      Log In
    </v-btn>
    </v-container>  
</v-container>
</template>
<script>
export default {
    name: 'userConfirmation',
    props:[''],
    components: {
      
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
    },    
    mounted(){
      //dopo il mount
         this.axios({
            method: 'post',
            url: 'auth/userConf',
            data:{
              token: this.$route.query.token
            }
          })
        .then(function (response) {
            //self.$router.push("/login");
            console.log(response.data)
        });

    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        
    }),
    methods: {
      gotoToPage(page){
          if (page!==this.$route.path) this.$router.push(page);
      },  
      login(){
        this.gotoToPage("/userLogin")
      }


    },
    watch: {

    },    
}
</script>