<template>
<div>
    <v-container v-show="screen==='reqchngpwd'">
        <v-container class="d-flex justify-center mt-0">
            <v-card
            elevation="0">
            <img 
            alt="TreeTraker logo" 
            width="100"
            contain
            src="../assets/logo-green.png"/>
            </v-card>
        </v-container>
        <v-container class="d-flex justify-center mt-0">
        <img 
            alt="TreeTraker logo2" 
            :width="widthGrafica"
            class="align-self-stretch"
            contain        
            src="../assets/logo-image.png"
            height="150"
            /> 
        </v-container>        
        <v-container class="d-flex justify-center mt-0">
            <v-col cols="12">  
        <v-row>
            <span class="caption mt-0"> E-mail</span>
        </v-row><v-row>
        <v-text-field
        v-model="email"
        required
        :rules="emailRules"
        clearable
        outlined
        dense
        color="#215356"      
        />  </v-row> </v-col> 
        </v-container>      
        <v-container class="d-flex justify-center mt-0">  
        <v-btn
            small
            elevation="3"
            color="#215356"
            class="white--text"
            @click="reqChangePWD"
        >
        request change password 
        </v-btn>
        </v-container>  
    </v-container>
    <v-container v-show="screen==='reqPwd'">
        <v-container class="d-flex justify-center mt-0">
            <v-card
            elevation="0">
            <img 
            alt="TreeTraker logo" 
            width="100"
            contain
            src="../assets/logo-green.png"/>
            </v-card>
        </v-container>
  
        <v-container class="d-flex justify-center mt-0">
            <v-col cols="12">  
        <v-row>
            <span class="caption mt-0"> Password</span>
        </v-row><v-row>
        <v-text-field
        v-model="pwd1"
        required
        :rules="[passwordRules.required, passwordRules.min]"
        type="password"
        clearable
        outlined
        dense
        color="#215356"      
        />  </v-row> 
        <v-row>
            <span class="caption mt-0"> Rewrite Password</span>
        </v-row><v-row>
        <v-text-field
        v-model="pwd2"
        required
        type="password"
        :rules="[passwordRules.required, passwordRules.min]"
        clearable
        outlined
        dense
        color="#215356"      
        />  </v-row>
        </v-col> 
        </v-container>      
        <v-container class="d-flex justify-center mt-0">  
        <v-btn
            small
            elevation="3"
            color="#215356"
            class="white--text"
            @click="chgPWD"
            :disabled="(pwd1!==pwd2)||(pwd1===null)||(pwd2===null)||(pwd1==='')||(pwd2==='')"
        >
        save password
        </v-btn>
        </v-container>      
    </v-container>
</div>
</template>

<script>
export default {
    name: 'reqchngpwd',
    props:[''],
    components: {
      
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
      this.screen = (this.$route.query.codez) ? "reqPwd" : "reqchngpwd"
    },    
    mounted(){
      //dopo il mount
    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        screen:"reqchngpwd",
        passwordRules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
        }, 
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ], 
        email:null,
        pwd1:null,
        pwd2:null,                  
    }),
    methods: {
      chgPWD(){
        var req = {
          cod:this.$route.query.codez,
          newPwd:this.pwd1
        }
        console.log(req)
        this.axios({
        method: 'post',
        url: 'auth/changePwd',
        data: req
  
        }).then((response) => {

          console.log(response.data)
          //this.gotoToPage("/userLogin")
          setTimeout(() => {
            this.gotoToPage("/userLogin")
          }, 200);

        }); 
      },
        reqChangePWD(){
        var req = {
          email:this.email
        }
        console.log(req)
        this.axios({
        method: 'post',
        url: 'auth/cambioPwd',
        data: req
  
        }).then((response) => {

          console.log(response.data)
          this.gotoToPage("/reqChangePwdMessage")

        });   
        },
      gotoToPage(page){
          if (page!==this.$route.path) this.$router.push(page);
      },   
    },
    watch: {

    },    
}
</script>