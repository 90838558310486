<template>
<div class="mt-2">
    <v-row>
        <v-col cols="3">
            <v-img
                :src="'data:image/png;base64,'+treeInfo.mainImageFilePath_imgS"
            />
        </v-col>    
        <v-col cols="6">
            <v-row>
            <span
            class="littleLittleFonts"
            >
                <span class="font-weight-bold">
                {{(treeInfo.commonName) ? treeInfo.commonName : treeInfo.scientificName}}   
                </span>
                <p>
                {{treeInfo.scientificName}}
                </p>   
            </span>  </v-row>
            <v-row>
                <v-icon dense color="#215356" @click="treeDetail">mdi-chevron-down</v-icon>
            </v-row>
        </v-col>  
        <v-col cols="1">
            <v-btn 
                x-small 
                class="mr-3"
                elevation="0"
                @click="selectThisTree"
            >
                Select
            </v-btn>
        </v-col>                  
    </v-row>
    <v-row>
        <v-col v-show="theDetails">
            <v-row>
                <v-divider/>
            </v-row>
            <v-row >
                <span class="littleLittleFonts mt-1 ml-2 verdone">
                    
                </span>    
            </v-row>
            <v-row>
                <v-btn
                    class="mt-3 ml-0"
                    elevation="0"
                    color="white"
                    x-small
                    @click="treeStory"
                >
                    <span
                    class="verdone"
                    >
                        Learn More
                    </span>
                <v-icon
                    right
                    dark
                    x-small
                >
                    mdi-greater-than
                </v-icon>                
                </v-btn>                
            </v-row>            
        </v-col>       
    </v-row>
</div>
</template>
<style scoped>
.littleLittleFonts{
    font-size: 10px;
    line-height: 1;
    font-weight: normal;
    margin: 0;
    padding: 0;
}
.lessLittleFonts{
    font-size: 13px;
    line-height: 1;
    font-weight: normal;
    margin: 0;
    padding: 0;
}
.bordersUp {
    background-color: #ffffff !important;
    border-top: 1px solid #215356 !important 
}
.verdone {
    color: #215356
}
</style>
<script>
import { serverBus } from '../main';
export default {
    name: 'treeInfo',
    props:['treeInfo'],
    components: {
      
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
    },    
    mounted(){
      //dopo il mount
    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        theDetails:false,
    }),
    methods: {
        treeDetail(){
            this.theDetails = !this.theDetails
        },
        treeStory(){
            serverBus.$emit("treeStory",this.treeInfo);
        },
        selectThisTree(){
            serverBus.$emit("selectThisTree",this.treeInfo);
        }
    },
    watch: {

    },    
}
</script>