<template>
<v-img
  height="110%"
  width="100%"
  class="pt-2 pb-8 pl-1 pr-1"
>
    <l-map 
        :zoom="zoom" 
        :center="coordCenter"
        ref="mappa"
        @update:zoom="zoomUpdated"
    > <!--       -->
        <l-circle
            :lat-lng="cerchio.coords"
            :radius="circle.radius"
            :color="circle.color"
            v-for="cerchio in cerchi"
            :key="cerchio.id"
            interactive
        />    
        <l-tile-layer :url="url"></l-tile-layer>
       <!-- <l-marker 
            :lat-lng="coordCenter" 
            visible
        ></l-marker>
         <l-polygon :lat-lngs="coordPoly" :color="polygon.color"></l-polygon> -->

    </l-map>
</v-img>

</template>

<script>

//import selectAreaFeature from 'leaflet-selectareafeature'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
import "leaflet/dist/leaflet.css"
import { serverBus } from '../main';
import {LMap, LTileLayer, LMarker,LPolygon,LCircle,LGeoJson,LControl} from 'vue2-leaflet';
export default {
    name: 'treeMapViewPreview',
    props:['coordPoly','coordCenter'],
    components: {
        LMap,
        LTileLayer,
        LGeoJson,
        LMarker,
        LPolygon,
        LCircle,
        LControl,
        //selectAreaFeature
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione

    },
    async created () {
        /*
        const response = await fetch('https://rawgit.com/gregoiredavid/france-geojson/master/regions/pays-de-la-loire/communes-pays-de-la-loire.geojson');
        this.geojson = await response.json();
        */
    },
    beforeMount(){
      //prima del mount
      /*
      serverBus.$on("updateMapPointPreview",() =>{
        console.log("coords update")
        navigator.geolocation.getCurrentPosition(position => {
            console.log(position)
            this.center=[position.coords.latitude,position.coords.longitude]
            this.markerLatLng=[position.coords.latitude,position.coords.longitude]
        })  
      });  */       
      serverBus.$on("updateMapPointPreviewTreeStory",(puntoCentrale,poligono,zoom) =>{
            console.log("updateMapPointPreview")
            console.log(puntoCentrale)
            console.log("updateMapPointPreviewPOly")
            console.log(poligono)            
            this.center=[puntoCentrale.latitude,puntoCentrale.longitude]
            this.markerLatLng=[puntoCentrale.latitude,puntoCentrale.longitude]
            
      });          
    },    
    mounted(){
      //dopo il mount
      /*
      this.$nextTick(() => {
        this.$refs.mappa.mapObject.getZoom();
      });*/
/*
    setInterval(() => {
      console.log("getPos")
        navigator.geolocation.getCurrentPosition(position => {
            console.log(position)
            this.center=[position.coords.latitude,position.coords.longitude]
            this.markerLatLng=[position.coords.latitude,position.coords.longitude]
        },error =>{
          console.log("ERRORE:"+error.code+" "+error.message)
        },{
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0
        })        
    }, 500);  
*//*
      this.center = this.coordCenter
      this.markerLatLng = this.coordCenter
    const watchID = navigator.geolocation.watchPosition((position) => {
      console.log("aggiornaPosizione")
      this.center=[position.coords.latitude,position.coords.longitude]
      this.markerLatLng=[position.coords.latitude,position.coords.longitude]
    });

*/

    //var mappa = this.$refs.mappa.mapObject
    //console.log(mappa)
    //var selectfeature = mappa.selectAreaFeature.enable();    

    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
      //console.log("update")
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 18,
        center: [45.480938300000005,10],
        markerLatLng: [45.480938300000005,10],
        geojson: null, 
        polygon: {
            latlngs: [],
            color: 'green'
        }, 
        circle: {
            center: [],
            radius: 2,
            color: 'white'
        },
        cerchi:[],
        moveCircle:false,
        circleArray:0,
        coordMove:[],  
        map:null,   
        ciao:null,
        readOnlyMap:false     
    }),
    methods: {
        zoomUpdated(){
          switch(this.$refs.mappa.mapObject.getZoom()){
            case(18):
            case(16):
              var radius = 2
              break
            case(14):
            case(12):
              var radius = 3
              break
            case(10):
            case(8):
              var radius = 4  
              break                        
          }
          this.circle.radius = radius
          console.log("zoomPreview:"+this.$refs.mappa.mapObject.getZoom())
        },
        visuMap(){

        },
        acceptMap(){
          //console.log(this.polygon.latlngs)
          console.log(this.$refs.mappa.mapObject.getZoom())

        },
        exitMap(){
          serverBus.$emit("backToNewTree");
        },
        clearMap(){
            this.polygon.latlngs = []
            this.cerchi = []
            this.circleArray = 0
            serverBus.$emit("updateMapPoint");
        },
        cambia(value){
            console.log(value)
            this.markers[2] = value
        },
        cliccasumappa(val){
            console.log(val)
            if (!this.moveCircle){
                this.polygon.latlngs.push(val.latlng)
                this.cerchi.push({coords:val.latlng,id:this.circleArray++})
                this.moveCircle = false
            } else {
                
            }
            console.log(this.polygon.latlngs)
        },
        cliccaCerchio(val){
            console.log("cliccatoCerchio")
            this.coordMove = val.latlng
            //this.moveCircle = true
            console.log(this.cerchi)
        },

    },
    watch: {

    },    
}
</script>