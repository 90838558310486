//import { openDB, deleteDB, wrap, unwrap } from 'idb';

function createLocalDb(dbName){
    let request = window.indexedDB.open(dbName, 1);
    request.onerror = function() {
        alert('Database failed to open');
    };
    // onsuccess handler signifies that the database opened successfully
    request.onsuccess = function() {
        console.log('Database opened successfully');

    };     
    
    // Setup the database tables if this has not already been done
    request.onupgradeneeded = function(e) {
        // Grab a reference to the opened database
        let db = e.target.result;
  
        // Create an objectStore to store our notes in (basically like a single table)
        // including a auto-incrementing key
        let objectStore = db.createObjectStore(dbName, { keyPath: 'id', autoIncrement:true });
  
        // Define what data items the objectStore will contain
        objectStore.createIndex('treeName', 'treeName', { unique: false });
  
        console.log('Database setup complete');
    };        
}

  function deleteDataFromLocalDb(objId,dbName){
    let request = window.indexedDB.open(dbName, 1);
  
    request.onerror = function() {
      console.log('Database failed to open');
    };
    // onsuccess handler signifies that the database opened successfully
    request.onsuccess = function() {
      console.log('Database opened successfully');
  
      // Store the opened database object in the db variable. This is used a lot below
  
      console.log("redbbb")
      console.log(request.result)
  
      var db = request.result;     
  
      var request2 = db.transaction([dbName], "readwrite")
                      .objectStore(dbName)
                      .delete(objId);
      request2.onsuccess = function(event) {
        // It's gone!
      };
    }
    
  }
  function getLocalData(objId,dbName){

    var rit = [];
    console.log("dbname:"+dbName)
    console.log("id:"+objId)
    let request = window.indexedDB.open(dbName, 1);
  
    request.onerror = function() {
      console.log('Database failed to open');
    };
    // onsuccess handler signifies that the database opened successfully
    request.onsuccess = function() {
      console.log('Database opened successfully');
  
      // Store the opened database object in the db variable. This is used a lot below
  
      console.log("redbbb")
      console.log(request.result)
  
      var db = request.result;     
  
    var objectStore = db.transaction([dbName], "readwrite").objectStore(dbName);
    var request2 = objectStore.get(objId);
    request2.onerror = function(event) {
      // Handle errors!
    };
    request2.onsuccess = function(event) {
      // Get the old value that we want to update
      var dataLocal = event.target.result;
  
      
      console.log("dataLocal")
      console.log(dataLocal)
  
  
      console.log("id:"+objId)
  
      rit = dataLocal

      return rit
      
    };
    }
    //rit = "ciao"
  
    /*
    const db = openDB(dbName, "1", {
      upgrade(db, oldVersion, newVersion, transaction) {
        // …
      },
      blocked() {
        // …
      },
      blocking() {
        // …
      },
      terminated() {
        // …
      },
    });
    const store = db.transaction(dbName).objectStore(dbName);
    const value =  store.get(objId);

  */

    return rit
  }
  function updateDataToLocalDb(objId,data,dbName){
    console.log("dbname:"+dbName)
    let request = window.indexedDB.open(dbName, 1);
  
    request.onerror = function() {
      console.log('Database failed to open');
    };
    // onsuccess handler signifies that the database opened successfully
    request.onsuccess = function() {
      console.log('Database opened successfully');
  
      // Store the opened database object in the db variable. This is used a lot below
  
      console.log("redbbb")
      console.log(request.result)
  
      var db = request.result;     
  
    var objectStore = db.transaction([dbName], "readwrite").objectStore(dbName);
    var request2 = objectStore.get(objId);
    request2.onerror = function(event) {
      // Handle errors!
    };
    request2.onsuccess = function(event) {
      // Get the old value that we want to update
      var dataLocal = event.target.result;
  
      
      console.log("dataLocal")
      console.log(dataLocal)
      console.log("dataNuova")
      console.log(data)
      dataLocal = data
  
  
      console.log("id:"+objId)
      var requestUpdate = objectStore.put(data);
      requestUpdate.onerror = function(event) {
        // Do something with the error
      };
      requestUpdate.onsuccess = function(event) {
        // Success - the data is updated!
        console.log("successUpdate")
      };
    };
    }
    
  }
  function saveDataToLocalDb(data,dbName){
    let request = window.indexedDB.open(dbName, 1);
    request.onerror = function() {
      console.log('Database failed to open');
    };
    // onsuccess handler signifies that the database opened successfully
    request.onsuccess = function() {
      console.log('Database opened successfully');
  
      // Store the opened database object in the db variable. This is used a lot below
      var db = request.result;
  
      // open a read/write db transaction, ready for adding the data
      let transaction = db.transaction([dbName], 'readwrite');
      // call an object store that's already been added to the database
      let objectStore = transaction.objectStore(dbName);         
      // Make a request to add our newItem object to the object store
      let request2 = objectStore.add(data);
      request2.onsuccess = function() {
        // Clear the form, ready for adding the next entry
  
      };
  
      // Report on the success of the transaction completing, when everything is done
      transaction.oncomplete = function() {
        console.log('Transaction completed: database modification finished.');
  
        // update the display of data to show the newly added item, by running displayData() again.
        //displayData();
      };
  
      transaction.onerror = function() {
        console.log('Transaction not opened due to error');
      };   
    };  
  }
  function createLocalTreeListWithData(dbName){
    var ritornoData = []
    let request = window.indexedDB.open(dbName, 1);
    request.onerror = function() {
        console.log('Database failed to open');
    };
    // onsuccess handler signifies that the database opened successfully
    request.onsuccess = function() {
      
        console.log('Database opened successfully');
  
        // Store the opened database object in the db variable. This is used a lot below
        var db = request.result;
  
        console.log("result")
        console.log(db)
  
        // open a read/write db transaction, ready for adding the data
        let transaction = db.transaction([dbName]);
        // call an object store that's already been added to the database
        let objectStore = transaction.objectStore(dbName);  
        
        objectStore.openCursor().onsuccess = function(event) {
        var cursor = event.target.result;
        var indice = 0
        if (cursor) {
          var photoHtml = (cursor.value.photoBlob!==null) ? window.URL.createObjectURL(cursor.value.photoBlob) : null
          var data = 
            {  treeName:cursor.value.treeName,
              photoBlob:photoHtml,
              realBlob:cursor.value.photoBlob,
              localTree:true,
              owner:cursor.value.treeOwner,
              syncId:cursor.value.syncId,
              plantationDate:cursor.value.plantationDate,
              selectedTreeType:cursor.value.selectedTreeType,
              treeHeight:cursor.value.treeHeight,
              treeWidth:cursor.value.treeWidth,
              id:cursor.value.id
            }
  
          ritornoData.push(data)
          console.log(data)
          //ritornoData[] = data
          cursor.continue();
  
        }
        else {
  
        }
        
        
        };
        
        // Make a request to add our newItem object to the object store
        let request2 = objectStore.get("");
  
        request2.onsuccess = function(event) {
        // Clear the form, ready for adding the next entry
        console.log('Richiesta creazione OK.');
        console.log(event)
        };
  
        // Report on the success of the transaction completing, when everything is done
        transaction.oncomplete = function() {
  
        // update the display of data to show the newly added item, by running displayData() again.
        //displayData();
        };
  
        transaction.onerror = function() {
        console.log('Recupero fallito');
        };   
    };      
    console.log("finito alberi lista locali");
    console.log(ritornoData)
    this.tempRemoteUpload = ritornoData
    return ritornoData
  }
  export {getLocalData,saveDataToLocalDb};
  export default createLocalDb;