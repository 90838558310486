<template>

  <tree-map-view
  :readOnlyMap="readOnlyMap"
  >



  </tree-map-view>

</template>

<script>
import treeMapView from '../components/treeMapView.vue'
import { serverBus } from '../main';
export default {
    name: 'treeMap',
    props:[''],
    components: {
      treeMapView
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
    },    
    mounted(){
      //dopo il mount
      setTimeout(() => {
        serverBus.$emit("updateMapPoint");
      }, 300);
    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        readOnlyMap:true
    }),
    methods: {


    },
    watch: {

    },    
}
</script>