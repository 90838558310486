<template>
    <v-container v-show="attivo">
      <p>{{hostTemp}}</p>
        <v-btn
          @click="tik"
        > TIK</v-btn>
        <v-btn
          @click="imgToDb"
        > Img To Db</v-btn>  
        <v-btn
          class="mt-4"
          @click="sendEmailTest"
        > SendEMAILTEST</v-btn>          
        <v-btn
          class="mt-4"
          @click="testLetturaFileExcel"
        > testExcelReading</v-btn>               
        <v-card>
            {{ritorno}}
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'MgmDb',
    props:[''],
    components: {
      
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
    },    
    mounted(){
      //dopo il mount
      this.attivo = (window.location.host==="localhost:8080")

    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        ritorno:null,
        hostTemp:null,
        attivo:false
    }),
    methods: {
        tik(){
            var data = {dio:"ciao",madonna:"ciao"}
            console.log("tik")
            this.axios({
            method: 'post',
            url: 'mgme/dbWorks',
            responseType: 'blob',
            data: data
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                console.log("file")
                //console.log(response.data);
                //var dataOggi = new Date().getTime();
                link.setAttribute('download','TreeSpecies.xlsx'); 
                document.body.appendChild(link);
                console.log("Link:"+link);
                link.click();
            }); 
        },
        imgToDb(){
          var data = {dio:"ciao",madonna:"ciao"}
          console.log("tik")
          this.axios({
          method: 'POST',
          url: 'mgme/imgToDb',
          responseType: 'json',
          data: data
          }).then((response) => {
            console.log(response)
          }); 
        },
        sendEmailTest(){
          console.log("tik")
          this.axios({
          method: 'POST',
          url: 'email/sendEmailAzuse',
          responseType: 'json'
          }).then((response) => {
            console.log(response)
          }); 
        },
        testLetturaFileExcel(){
          var data = {dio:"ciao",madonna:"ciao"}
          console.log("tik")
          this.axios({
          method: 'POST',
          url: 'mgme/updateTreeDatas',
          responseType: 'json',
          data: data
          }).then((response) => {
            console.log(response)
          });           
        }
    },
    watch: {

    },    
}
</script>