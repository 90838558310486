<template>
<v-card
height="150px"
width="200px"
class="mt-1 mb-1 ml-1">
    <v-card-title class="caption">{{userData.firstName}} {{userData.lastName}}</v-card-title>
    <v-card-text>
        <v-card height="50px" elevation="0">
        <h6>{{userData.email}}</h6>
        <h6>{{userData.phone}}</h6>
        <h6>{{userData.address}}</h6>
        </v-card>
    </v-card-text>
    <v-card-actions>
        <v-btn 
            v-if="userData.active==='0'" 
            x-small 
            elevation="2"
            color="#215356"
            class="ml-2 white--text"
            @click="userActions('activate')">
        ACTIVATE</v-btn>
        <v-btn 
            v-else
            x-small
            elevation="2"
            color="#FFF"
            class="ml-2 greenColor"  
            @click="userActions('deactivate')"                      
        >DEACTIVATE</v-btn>
        <v-btn 
            v-if="userData.userLocked==='0'" 
            x-small 
            elevation="2"
            color="#215356"
            class="ml-2 white--text"
            @click="userActions('lock')"            
            >
        LOCK</v-btn>
        <v-btn 
            v-else
            x-small
            elevation="2"
            color="#FFF"
            class="ml-2 greenColor"
            @click="userActions('unlock')"                        
        >UNLOCK</v-btn>        
    </v-card-actions>
</v-card>
</template>
<style scoped>
.scroll {
   overflow-y: scroll
}
.greenColor{
  color:#215356
}
</style>
<script>
import { serverBus } from '../main';
export default {
    name: 'userCard',
    props:['userData'],
    components: {
      
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
    },    
    mounted(){
      //dopo il mount
    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        
    }),
    methods: {
      userActions(action){
        console.log(action+" "+this.userData.id)
        switch(action){
            case("activate"):
                this.userData.active = "1";
            break;
            case("deactivate"):
                this.userData.active = "0";            
            break;
            case("lock"):
                this.userData.userLocked = "1"; 
            break;
            case("unlock"):
                this.userData.userLocked = "0";
            break;
        }
        var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
        method: 'post',
        url: 'mgme/userActions',
        data: {
          tok:locSto,
          action:action,
          userId:this.userData.id
        }
        }).then((response) => {     
            console.log(response.data)
            serverBus.$emit("updateUserStatus");
        });              
        }        
      },
      testemail(){
          //
          console.log("testemail")
      var locSto = localStorage.getItem("tok");
      console.log("loc:"+locSto)
      this.axios({
      method: 'post',
      url: 'email/testemail',
      data: {
        tok:locSto,

      }
      }).then((response) => {     

          console.log("risposta invio")
          console.log(response.data)

      });              


    },
    watch: {

    },    
}
</script>