<template>
<div>

    <v-card v-show="step===1" elevation="0">
        <v-card-text>
       
        <h6 class="greenColor">* Required fields</h6>
        <v-icon color="#215356" dense>mdi-tree</v-icon><span class="caption mt-0 greenColor"> Tree Name?*</span>
        <v-text-field
        v-model="treeName"
        required
        clearable
        dense
        outlined
        color="#215356"
        class="ml-4 mr-4"
        />              
        <v-icon color="#215356" dense>mdi-account</v-icon><span class="caption mt-0 greenColor"> Who are you adding this tree for?*</span>
        <v-text-field
        v-model="treeOwner"
        required
        clearable
        dense
        outlined
        color="#215356"
        class="ml-4 mr-4"
        />    
        <v-icon color="#215356" dense>mdi-calendar</v-icon><span class="caption mt-0 greenColor"> Date of plantation*</span>
      <v-dialog
        ref="dialog"
        v-model="modalDate"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="ComputedplantationDate"
            readonly
            v-bind="attrs"
            outlined
            v-on="on"
            dense
            class="ml-4 mr-4"
            color="#215356"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="plantationDate"
          color="#215356"
          @input="modalDate = false"
          
        >

        </v-date-picker>
      </v-dialog>
        </v-card-text>   
    <v-card-actions>
    <v-container class="d-flex justify-center mt-1">  
    <v-btn
        small
        elevation="3"
        color="error"
        class="greenColor"
        @click="deleteIt"
      >
      DELETE
    </v-btn>       
      <v-btn
        small
        elevation="3"
        color="#215356"
        class="white--text ml-4"
        @click="nextStep"
      >
      Next
    </v-btn>
   
    </v-container> 

    </v-card-actions>
    
    </v-card>
    <v-card v-show="step===2" elevation="0">
        <v-card-text>
        <h6 class="greenColor">* Required Fields</h6>
        <v-icon dense color="#215356">mdi-leaf</v-icon><span class="caption mt-0 greenColor"> Type of tree*</span>
        <v-select
          class="mt-2"
          :items="itemsTreesType"
          @change="selectTreeType"
          v-model="selectedTreeType"
          dense
          outlined
          color="#215356"       
        ></v-select>          
        <v-icon dense color="#215356">mdi-ruler</v-icon><span class="caption mt-0 greenColor"> Measure your tree?*</span>
        <v-row>
          <v-col cols="6">
            <v-text-field
            v-model="treeHeight"
            placeholder="Height"
            required
            clearable
            dense
            outlined
            color="#215356"
            class="ml-1"
            />            
          </v-col>
          <v-col cols="6">
            <v-text-field
            v-model="treeWidth"
            placeholder="Width"
            required
            clearable
            dense
            outlined
            color="#215356"
            class="ml-1"
            />            
          </v-col>          
        </v-row>

    <v-container class="d-flex justify-center mt-1" style="height:150px">  
    <v-row>
      <v-col cols="6">
      <v-img :src="photoHtml" max-width="200px" />
      </v-col>
      <v-col cols="6">
    <v-btn
        large
        elevation="3"
        color="#FFF"
        class="greenColor ml-6"
        @click="takePicture"
        rounded

      >
      <v-icon dense color="#215356">mdi-camera</v-icon>
    </v-btn>  
      </v-col>
    </v-row>
    </v-container>
        </v-card-text>   
    <v-card-actions>
    <v-container class="d-flex justify-center mt-1">  
    <v-btn
        small
        elevation="3"
        color="error"
        class="greenColor"
        @click="deleteIt"
      >
      DELETE
    </v-btn>     
      <v-btn
        small
        elevation="3"
        color="#215356"
        class="white--text ml-4"
        @click="prevStep"
      >
      Previous
    </v-btn>      
      <v-btn
        small
        elevation="3"
        color="#215356"
        class="white--text ml-4"
        @click="saveSend"
      >
      Update and Send
    </v-btn>
   
    </v-container> 

    </v-card-actions>
    
    </v-card>
    <camera
      v-show="cameraOn"
    />

  <v-dialog
    v-model="dialogUploadOnlLine"
  >
      <v-card>
        <v-card-title class="text-h5">
          Upload to Online DB?
        </v-card-title>
        <v-card-text>Do you want to upload the tree in the cloud.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="saveLocallyGo"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="saveOnline"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>    

  </v-dialog>
  <v-dialog
    v-model="dialogDelete"
  >
      <v-card>
        <v-card-title class="text-h5">
          Delete?
        </v-card-title>
        <v-card-text>Do you want delete the tree?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogDelete=false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            text
            @click="deleteItNow"
          >
            Delete it
          </v-btn>
        </v-card-actions>
      </v-card>    

  </v-dialog>  
</div>
</template>
<style scoped>
.scroll {
   overflow-y: scroll
}
.greenColor{
  color:#215356
}
</style>
<script>
import camera from '../components/camera.vue'
import { serverBus } from '../main';
//import { initJsStore } from "../service/idb_service";
//import { Global } from "../global.js";
export default {
    name: 'addNewTree',
    props:[''],
    components: {
      camera,
    },

    computed:{
      ComputedplantationDate () {
        return this.formatDate(this.plantationDate)
      },
    },
  async beforeCreate() {
    /*
    try {
      const isDbCreated = await initJsStore();
      if (isDbCreated) {
        console.log("db created");
        // prefill database
      } else {
        console.log("db opened");
      }
    } catch (ex) {
      console.error(ex);
      alert(ex.message);
      Global.isIndexedDbSupported = false;
    }
    */
  },
    created(){
      serverBus.$on("giveMeTreePhoto",(photoBlob) =>{
        console.log("salva blob e html")
        this.photoBlob = photoBlob
        this.photoHtml = window.URL.createObjectURL(photoBlob)  
        this.step =2   
        this.cameraOn = false

      });
      serverBus.$on("treeDataDraft",(treeData) =>{
        console.log("treeDataDraft")
        this.localTreeData = treeData
        console.log("treedataDDDDD")
        console.log(treeData)

        this.treeName = treeData.treeName
        this.plantationDate = treeData.plantationDate
        this.treeOwner = treeData.owner
        this.selectedTreeType = treeData.selectedTreeType
        this.treeHeight = treeData.treeHeight
        this.treeWidth = (treeData.treeWidth===null) ? null : treeData.treeWidth
        this.photoHtml = (treeData.realBlob===null) ? null : window.URL.createObjectURL(treeData.realBlob)
        this.photoBlob = (treeData.realBlob===null) ? null : treeData.realBlob
        this.treeId = treeData.id

        
      });      
    }, 
    beforeMount(){
      //prima del mount
      
    },    
    mounted(){
      //dopo il mount
      this.createLocalDb('treeCacheDraft')
      this.getTreeTypeList()
      console.log(this.$store.state.userData)
      //this.treeOwner = this.$store.state.userData.firstName+" "+this.$store.state.userData.lastName


      console.log("random:"+this.makeid(300))

    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        plantationDate:new Date().toISOString().substr(0, 10),
        treeName:null,
        treeOwner:null,
        selectedTreeType:null,
        treeHeight:null,
        treeWidth:null,
        selectedTType:null,
        treeId:null,

        modalDate:false,
        step:1,
        cameraOn:false,
        photoBlob:null,
        photoHtml:null,
        date:null,
        itemsTreesType:[],
        
        //dialog
        dialogUploadOnlLine:false,
        dialogDelete:false,
        localTreeData:[],

    }),
    methods: {
      saveLocallyGo(){
        if (((this.treeName!==null) || (this.treeName!=="")) && ((this.treeOwner!==null) || (this.treeOwner!=="")) && ((this.selectedTreeType!==null) || (this.selectedTreeType!==""))&& ((this.treeHeight!==null) || (this.treeHeight!==""))&& ((this.treeWidth!==null) || (this.treeWidth!==""))&& ((this.plantationDate!==null) || (this.plantationDate!==""))&& ((this.photoBlob!==null) || (this.photoBlob!==""))) {
          this.saveLocallyNoDraft('treeCache')
          setTimeout(() => {
            this.deleteDataToLocalDb(this.localTreeData.id,'treeCacheDraft')
          }, 300);
          
        } else {
          this.saveLocally('treeCacheDraft')
        }

        
      },
      deleteItNow(){
//
        let data = { id:this.treeId,treeName: this.treeName, treeOwner: this.treeOwner, selectedTreeType: this.selectedTreeType, treeHeight:this.treeHeight, treeWidth: this.treeWidth, plantationDate: this.plantationDate,photoBlob: this.photoBlob,syncId:this.makeid(200) };  
      this.deleteDataToLocalDb(this.localTreeData.id,'treeCacheDraft')
          setTimeout(() => {
            this.dialogDelete =false
            this.gotoToPage("/userHP")
          }, 500);        
      },
      deleteIt(){
        this.dialogDelete = true
      },
      saveLocallyNoDraft(dbName){
        console.log("saveLocally")
        let data = { id:this.treeId,treeName: this.treeName, treeOwner: this.treeOwner, selectedTreeType: this.selectedTreeType, treeHeight:this.treeHeight, treeWidth: this.treeWidth, plantationDate: this.plantationDate,photoBlob: this.photoBlob,syncId:this.makeid(200) };        
          this.saveDataToLocalDbNoDraft(data)
          console.log("dibbiname:"+dbName)
          //this.updateDataToLocalDb(this.localTreeData.id,data,dbName)
          setTimeout(() => {
            this.dialogUploadOnlLine =false
            this.gotoToPage("/userHP")
          }, 500);           
//
      },      
      saveLocally(dbName){
        console.log("saveLocally")
        let data = { id:this.treeId,treeName: this.treeName, treeOwner: this.treeOwner, selectedTreeType: this.selectedTreeType, treeHeight:this.treeHeight, treeWidth: this.treeWidth, plantationDate: this.plantationDate,photoBlob: this.photoBlob,syncId:this.makeid(200) };        
          //this.saveDataToLocalDb(data)
          console.log("dibbiname:"+dbName)
          this.updateDataToLocalDb(this.localTreeData.id,data,dbName)
          setTimeout(() => {
            this.dialogUploadOnlLine =false
            this.gotoToPage("/userHP")
          }, 500);           
//
      },
      saveOnline(){
        console.log("online")
//
        // userId treeName treeOwner treeType treeHeight treeWidth plantationDate photoBlob syncId flagDraft
        let data = { treeName: this.treeName, treeOwner: this.treeOwner, selectedTreeType: this.selectedTreeType, treeHeight:this.treeHeight, treeWidth: this.treeWidth, plantationDate: this.plantationDate,photoBlob: this.photoBlob,syncId:this.makeid(200) };

        var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
        method: 'post',
        url: 'trees/saveTree',
        data: {
          lok:locSto,
          tree: data,
        }
        }).then((response) => {
//
        });     


      },
        makeid(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * 
        charactersLength));
          }
          return result;
        },
      getTreeTypeList(){
          this.axios({
            method: 'get',
            url: 'trees/treeTypeList'
            }).then((response) => {
                //console.log(response.data)
                this.itemsTreesType = response.data
                console.log(response.data)
            });  
      },
      saveSend(){
        if (this.$store.state.OnLine) {
          this.dialogUploadOnlLine =true
        } else{
          this.saveLocally("treeCache")         
        }

      },
        gotoToPage(page){
        if (page!==this.$route.path) this.$router.push(page);
      },



      createLocalDb(dbName){
        let request = window.indexedDB.open(dbName, 1);
        request.onerror = function() {
          alert('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
          console.log('Database opened successfully');

          // Store the opened database object in the db variable. This is used a lot below
          var db = request.result;

          
          // Run the displayData() function to display the notes already in the IDB
          //displayData();
        };     
        
        // Setup the database tables if this has not already been done
        request.onupgradeneeded = function(e) {
          // Grab a reference to the opened database
          let db = e.target.result;

          // Create an objectStore to store our notes in (basically like a single table)
          // including a auto-incrementing key
          let objectStore = db.createObjectStore(dbName, { keyPath: 'id', autoIncrement:true });

          // Define what data items the objectStore will contain
          objectStore.createIndex('treeName', 'treeName', { unique: false });

          console.log('Database setup complete');
        };        
      },
      saveDataToLocalDb(data){
        let request = window.indexedDB.open('treeCacheDraft', 1);
        request.onerror = function() {
          console.log('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
          console.log('Database opened successfully');

          // Store the opened database object in the db variable. This is used a lot below
          var db = request.result;

          // open a read/write db transaction, ready for adding the data
          let transaction = db.transaction(['treeCacheDraft'], 'readwrite');
          // call an object store that's already been added to the database
          let objectStore = transaction.objectStore('treeCacheDraft');         
          // Make a request to add our newItem object to the object store
          let request2 = objectStore.add(data);
          request2.onsuccess = function() {
            // Clear the form, ready for adding the next entry

          };

          // Report on the success of the transaction completing, when everything is done
          transaction.oncomplete = function() {
            console.log('Transaction completed: database modification finished.');

            // update the display of data to show the newly added item, by running displayData() again.
            //displayData();
          };

          transaction.onerror = function() {
            console.log('Transaction not opened due to error');
          };   
        };  
      },
      saveDataToLocalDbNoDraft(data){
        let request = window.indexedDB.open('treeCache', 1);
        request.onerror = function() {
          console.log('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
          console.log('Database opened successfully');

          // Store the opened database object in the db variable. This is used a lot below
          var db = request.result;

          // open a read/write db transaction, ready for adding the data
          let transaction = db.transaction(['treeCache'], 'readwrite');
          // call an object store that's already been added to the database
          let objectStore = transaction.objectStore('treeCache');         
          // Make a request to add our newItem object to the object store
          let request2 = objectStore.add(data);
          request2.onsuccess = function() {
            // Clear the form, ready for adding the next entry

          };

          // Report on the success of the transaction completing, when everything is done
          transaction.oncomplete = function() {
            console.log('Transaction completed: database modification finished.');

            // update the display of data to show the newly added item, by running displayData() again.
            //displayData();
          };

          transaction.onerror = function() {
            console.log('Transaction not opened due to error');
          };   
        };  
      },
      updateDataToLocalDb(objId,data,dbName){
        console.log("dbname:"+dbName)
        let request = window.indexedDB.open(dbName, 1);

        request.onerror = function() {
          console.log('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
          console.log('Database opened successfully');

          // Store the opened database object in the db variable. This is used a lot below

          console.log("redbbb")
          console.log(request.result)

          var db = request.result;     

        var objectStore = db.transaction([dbName], "readwrite").objectStore(dbName);
        var request2 = objectStore.get(objId);
        request2.onerror = function(event) {
          // Handle errors!
        };
        request2.onsuccess = function(event) {
          // Get the old value that we want to update
          var dataLocal = event.target.result;

          
          console.log("dataLocal")
          console.log(dataLocal)
          console.log("dataNuova")
          console.log(data)
          dataLocal = data


          console.log("id:"+objId)
          var requestUpdate = objectStore.put(data);
          requestUpdate.onerror = function(event) {
            // Do something with the error
          };
          requestUpdate.onsuccess = function(event) {
            // Success - the data is updated!
            console.log("successUpdate")
          };
        };
        }
        
      },
      deleteDataToLocalDb(objId,dbName){
        let request = window.indexedDB.open(dbName, 1);

        request.onerror = function() {
          console.log('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
          console.log('Database opened successfully');

          // Store the opened database object in the db variable. This is used a lot below

          console.log("redbbb")
          console.log(request.result)

          var db = request.result;     

          var request2 = db.transaction([dbName], "readwrite")
                          .objectStore(dbName)
                          .delete(objId);
          request2.onsuccess = function(event) {
            // It's gone!
          };
        }
        
      },      
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      nextStep(){
        (this.step<2) ? this.step++ : this.step;
      },
      prevStep(){
        this.step--
      },
      takePicture(){
        this.step=null
        this.cameraOn = true
      },
      saveDraft(){
        //
      },
      selectOrg(){
        //
      },
      selectTreeType(value){
        this.selectedTreeType = value
      }
    },
    watch: {
      plantationDate(value){
        console.log(value)
      }
    },    
}
</script>