<template>
<div>
    <v-card v-show="step===1" elevation="0">
        <v-card-text>
            <h6 class="greenColor">* Required Fields</h6>
            <v-icon color="#215356" dense>mdi-calendar</v-icon><span class="caption mt-0 greenColor"> Update Date*</span>
            <v-dialog
                ref="dialog"
                v-model="modalDate"
                :return-value.sync="date"
                persistent
                width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="ComputedplantationDate"
                    readonly
                    v-bind="attrs"
                    outlined
                    v-on="on"
                    dense
                    class="ml-4 mr-4"
                    color="#215356"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="plantationDate"
                color="#215356"
                @input="modalDate = false"
                /> 
            </v-dialog>
            <v-icon dense color="#215356">mdi-ruler</v-icon><span class="caption mt-0 greenColor"> Measure your tree?*</span>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                    v-model="treeHeight"
                    placeholder="Height"
                    type="number"
                    required
                    clearable
                    dense
                    outlined
                    color="#215356"
                    class="ml-1"
                    />            
                </v-col>
                <v-col cols="6">
                    <v-text-field
                    v-model="treeWidth"
                    placeholder="Width"
                    type="number"
                    required
                    clearable
                    dense
                    outlined
                    color="#215356"
                    class="ml-1"
                    />            
                </v-col>          
            </v-row>
            <v-container class="d-flex justify-center mt-1" style="height:150px">  
                <v-row>
                    <v-col cols="6">
                        <v-img :src="photoHtml" max-width="200px" />
                    </v-col>
                    <v-col cols="6">
                        <v-btn
                            large
                            elevation="3"
                            color="#FFF"
                            class="greenColor ml-6"
                            @click="takePicture"
                            rounded
                        >
                            <v-icon dense color="#215356">mdi-camera</v-icon>
                        </v-btn>  
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>   
        <v-card-actions>
            <v-container class="d-flex justify-center mt-1">  
                <v-btn
                    small
                    elevation="3"
                    color="#215356"
                    class="white--text ml-4 mb-6"
                    @click="saveSend"
                >
                Save and Send
                </v-btn>
            </v-container> 
        </v-card-actions>
    </v-card>
    <camera
      v-show="cameraOn"
    />
    <v-dialog
        v-model="dialogUploadOnlLine"
    >
        <v-card>
            <v-card-title class="text-h5">
              Upload to Online DB?
            </v-card-title>
            <v-card-text>Do you want to upload the tree in the cloud.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="saveLocally"
              >
                Disagree
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="saveOnline"
              >
                Agree
              </v-btn>
            </v-card-actions>
        </v-card>    
    </v-dialog>
 
</div>
</template>
<style scoped>
.scroll {
   overflow-y: scroll
}
.greenColor{
  color:#215356
}
</style>
<script>
import camera from '../components/cameraRetake.vue'
import toDataURL from '../common/commonFunctions'
import { serverBus } from '../main';
import { set, get,createStore,entries, del} from 'idb-keyval';

export default {
    name: 'addNewDataTree',
    props:['treeData'],
    components: {
      camera,
    },

    computed:{
      ComputedplantationDate () {
        return this.formatDate(this.plantationDate)
      },
    },
    beforeMount(){
      //prima del mount
      serverBus.$on("saveCoordAndPoly",(photoBlob) =>{
        console.log("saveCoordAndPoly")
        

      });
      serverBus.$on("giveMeTreePhotoNewData",(photoBlob) =>{
        console.log("salva blob e html")
        this.photoBlob = photoBlob
        this.photoHtml = window.URL.createObjectURL(photoBlob)  
        this.step =1   
        this.cameraOn = false

      });
      serverBus.$on("takePictureOffLineNewData",(pippo) =>{
        this.step =1 
        this.cameraOn = false
        console.log("amera"+pippo)
        console.log("camera")
        console.log(this.cameraOn)
      });    
      serverBus.$on("viewDataAddedBefore",(pippo) =>{
        /*
        
        console.log("pippo:")
        console.log(pippo)
        this.plantationDate = (pippo.plantationDate) ? pippo.plantationDate.split(" ")[0] : null
        this.photoHtml = pippo.photoBlob
        this.treeHeight = pippo.treeHeight
        this.treeWidth = pippo.treeWidth
        */
          if (this.treeData.remoteId!==undefined){
          const customStore = createStore("remoteToLocalAddedData", "remoteToLocalAddedData");
          get(this.treeData.remoteId,customStore).then((val) => {
          if (val!==undefined){
            this.localAdded = true
            this.treeHeight = val.treeHeight
            this.treeWidth = val.treeWidth
            this.photoHtml = window.URL.createObjectURL(val.photoBlob)        
            console.log("c'e")      
          } else{ 
            console.log("VUOTO")
            console.log(val)
            this.localAdded = false
            this.treeHeight = null
            this.treeWidth = null
            this.photoHtml = null   } 
          })
}
      });          
    },   
    mounted(){
      //this.getTreeTypeList()
      //console.log(this.$store.state.userData)
      /*
     this.treeOwner = this.$store.state.userData.firstName+" "+this.$store.state.userData.lastName
      console.log("random:"+this.makeid(300))
      console.log("valoreInterno:")
      console.log(this.treeData)
      if (this.treeData) {
        this.plantationDate = (this.treeData.plantationDate) ? this.treeData.plantationDate.split(" ")[0] : null
        this.photoHtml = this.treeData.imageFilePath_imgL
        this.treeHeight = this.treeData.height_m
        this.treeWidth = this.treeData.diameter_cm
      }
      */
    },    
    data:()=> ({
        plantationDate:new Date().toISOString().substr(0, 10),
        treeName:null,
        treeOwner:null,
        selectedTreeType:null,
        treeHeight:null,
        treeWidth:null,

        modalDate:false,
        step:1,
        cameraOn:false,
        photoBlob:null,
        photoHtml:null,
        date:null,
        itemsTreesType:[],
        
        //dialog
        dialogUploadOnlLine:false,
        dialogUploadOnlLineDraft:false,

        

    }),
    methods: {

      saveLocally(){
        console.log("local")
        let data = { originalId: this.treeData.remoteId,treeName: this.treeName, treeOwner: this.treeOwner, selectedTreeType: this.selectedTreeType, treeHeight:this.treeHeight, treeWidth: this.treeWidth, plantationDate: this.plantationDate,photoBlob: this.photoBlob,syncId:this.makeid(200) }; 

          //this.saveDataToLocalDb(data,"treeCacheLocalAddedData")

          putLocalSingleData(data.originalId,data,"remoteToLocalAddedData","remoteToLocalAddedData")

          console.log(data)
          setTimeout(() => {
            this.dialogUploadOnlLine =false
            this.gotoToPage("/userHP")
          }, 500);           
      },      
      
      saveOnLineWithBlob(dataBlob){
          // userId treeName treeOwner treeType treeHeight treeWidth plantationDate photoBlob syncId flagDraft
          let data = { 
                      originalId:this.treeData.id,
                      operationDateTime: this.plantationDate,
                      height_m:this.treeHeight, 
                      diameter_cm: this.treeWidth, 
                      imageFilePath_imgL:dataBlob
                    };
          console.log("treeData:::")
          console.log(this.treeData)
          console.log("newData:::")
          console.log(data)
          var locSto = localStorage.getItem("tok");
          console.log("loc:"+locSto)
          this.axios({
          method: 'post',
          url: 'trees/updateExstTree',
          data: {
            tok:locSto,
            tree: data,
          }
          }).then((response) => {
  //
             this.dialogUploadOnlLine =false
            serverBus.$emit("moveOnPage1");
            console.log(response.data)
          });  
      },
      saveOnline(){
        console.log("online")
//
        toDataURL(this.photoHtml, this.saveOnLineWithBlob)
      },
        makeid(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
        charactersLength));
            }
            return result;
        },
      getTreeTypeList(){
          this.axios({
            method: 'get',
            url: 'trees/treeTypeList'
            }).then((response) => {
                //console.log(response.data)
                this.itemsTreesType = response.data
                console.log(response.data)
            });  
      },
      saveSend(){
        if (this.$store.state.OnLine) {
          this.dialogUploadOnlLine =true
        } else{
        let data = { originalId: this.originalId,treeName: this.treeName, treeOwner: this.treeOwner, selectedTreeType: this.selectedTreeType, treeHeight:this.treeHeight, treeWidth: this.treeWidth, plantationDate: this.plantationDate,photoBlob: this.photoBlob,syncId:this.makeid(200) };          
        console.log(data)
          this.saveDataToLocalDb(data,"treeCache")
          setTimeout(() => {
            this.gotoToPage("/userHP")
          }, 500);          
        }
      },
        gotoToPage(page){
        if (page!==this.$route.path) this.$router.push(page);
      },      
      saveDataToLocalDb(data,dbName){
        let request = window.indexedDB.open(dbName, 1);
        request.onerror = function() {
          console.log('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
          console.log('Database opened successfully');

          // Store the opened database object in the db variable. This is used a lot below
          var db = request.result;

          // open a read/write db transaction, ready for adding the data
          let transaction = db.transaction([dbName], 'readwrite');
          // call an object store that's already been added to the database
          let objectStore = transaction.objectStore(dbName);         
          // Make a request to add our newItem object to the object store
          let request2 = objectStore.add(data);
          request2.onsuccess = function() {
            // Clear the form, ready for adding the next entry

          };

          // Report on the success of the transaction completing, when everything is done
          transaction.oncomplete = function() {
            console.log('Transaction completed: database modification finished.');

            // update the display of data to show the newly added item, by running displayData() again.
            //displayData();
          };

          transaction.onerror = function() {
            console.log('Transaction not opened due to error');
          };   
        };  
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      nextStep(){
        if (this.step===1){
            if ((this.treeName===null) || (this.treeName==="")) {
                return
            }
        }
        (this.step<2) ? this.step++ : this.step;
      },
      takePicture(){
        this.step=null
        this.cameraOn = true
      },
      saveDraft(){
        //
        if (this.$store.state.OnLine) {
          this.dialogUploadOnlLineDraft =true
        } else{
        let data = { treeName: this.treeName, treeOwner: this.treeOwner, selectedTreeType: this.selectedTreeType, treeHeight:this.treeHeight, treeWidth: this.treeWidth, plantationDate: this.plantationDate,photoBlob: this.photoBlob,syncId:this.makeid(200) };          
          this.saveDataToLocalDb(data,"treeCacheDraft")
          setTimeout(() => {
            this.gotoToPage("/userHP")
          }, 500);          
        }

      },
      selectOrg(){
        //
      },
      selectTreeType(value){
        this.selectedTreeType = value
      }
    },
    watch: {
      plantationDate(value){
        console.log(value)
      }
    },     
}

async function putLocalSingleData(dataId,data,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    set(dataId,data,customStore)
}

</script>





