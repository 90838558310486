<template>
  <v-card
    class="mx-auto ml-4"
    height="270px"
    width="230"
    max-width="344px"
    elevation="3"
    
  >
    <v-img
      :src="tree.photoBlob"
      height="140"
      aspect-ratio="1"
      class="mx-0"
      @click="viewTree"
    />
    <v-card-text>
    <span @click="viewTree" class="greenColor font-weight-bold ml-0 mt-6">{{tree.treeName}} </span>

    
    <br>
    <span @click="viewTree" class="greenColor text-subtitle-3 mt-4">Last tree dimensions: <br>{{tree.treeHeight}}m {{tree.treeWidth}}cm </span>
    <br>
    <span @click="viewTree" class="greenColor ml-0 mt-6">Owner:{{tree.owner}} </span>

      <v-row>
        <v-col cols="4">
          <v-btn
            icon
            x-small
            color="#215356"
            class="ml-0"
            rounded
            @click="downloadLocally(tree)"
          >
          <v-icon>
          {{(tree.publicTree) ? "" : ((!tree.localTree) ? ((tree.fkApprovalStatus=='3') ? "mdi-arrow-collapse-down" : "" ): "mdi-clipboard-arrow-down")}}
          </v-icon>  
          </v-btn>          
        </v-col>
        <v-col cols="8">
          <!--<v-icon small color="green">{{cardStatus(tree.fkApprovalStatus)}}</v-icon>-->
          <v-icon x-small :color="statusBall">mdi-checkbox-blank-circle</v-icon>
          <span class="littleText ml-0 mt-6 text--center" :style="statusTextColor">{{cardStatusText(tree.fkApprovalStatus)}}</span>
        </v-col>        
      </v-row>

    <!--
      <v-col> checkbox-blank-circle
        <v-row>
          <p class="text-caption font-weight-bold greenColor">
            <v-icon small color="green">{{((tree.localTree) && (tree.fkApprovalStatus!=='5')) ? "mdi-sync-off" : ""}}</v-icon>

            <v-icon small color="green">{{(localMeasure) ? "mdi-sync-off" : ""}}</v-icon>
            <v-icon small color="green">{{(localAdded) ? "mdi-cake" : ""}}</v-icon>
          </p>

        </v-row>
        <v-row>
          <p class="text-caption text--disabled greenColor">{{tree.owner}}</p>
        </v-row>
      </v-col>
      -->
    </v-card-text>
  </v-card>
</template>
<style scoped>
.scroll {
   overflow-y: scroll
}
.greenColor{
  color:#215356
}
.littleText{
  font-size: x-small;
}
</style>
<script>
import { serverBus } from '../main';
import {getLocalData} from '../common/localDbFunctions'
import { set, get,createStore,entries, del} from 'idb-keyval';


export default {
    name: 'cardMyTreePreview',
    props:['tree','type'],
    components: {
      
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
    },    
    mounted(){
      //dopo il mount
      this.db = (this.type==="draft") ? this.tree.draft = true : this.tree.draft = false
      //this.dbName = (this.type==="draft") ? "treeDataDraft" : "treeData"
      this.dbName = (this.type==="draft") ? "treeDataDraft" : "compileForm"
      //this.dbPath = (this.type==="draft") ? "/modTreeDraft" : "/modTree"
      this.dbPath = (this.type==="draft") ? "/addNewTree" : "/addNewTree"
      if (this.tree.fkApprovalStatus==='5') {
        //this.getTreeEntriesForLocalAddData()
      }
      setTimeout(() => {
        console.log("db:"+this.db+" dbName:"+this.dbName+" path:"+this.dbPath)
      }, 2000);
    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        db:null,
        dbName:null,
        dbPath:null,
        singleTreeEntries:[],
        localMeasure:false,
        localAdded:false,
        statusBall:"green",
        statusTextColor:"color:green"
    }),
    methods: {
      downloadLocally(id){
        console.log("downloadLocally:")
        console.log(id)
      },
      getTreeEntriesForLocalAddData(){
        this.singleTreeEntries = []
        console.log("getTreeInfo")
        console.log(this.tree)
        var idTree = this.tree.remoteId
        var ciccio = getLocalData(idTree,"treeCacheLocalAddedData")
        console.log("ciccio:")
        console.log(ciccio)
      },
      cardLocalDataAdded(){
          const customStore = createStore("remoteToLocalAddedData", "remoteToLocalAddedData");

          get(this.tree.remoteId,customStore).then((val) => {
            if (val!==undefined){
              this.localAdded = true
              console.log("trovatoLocale")
          }})
      },    
      setColors(color){
        this.statusBall = color
        this.statusTextColor = "color:"+color
      },
      cardStatusText(status){
        console.log("approvazzo:"+status)
        if (status===undefined) {
          this.setColors("black")
          return " Local"
        }
//,[fkApprovalStatus]   1=bozza 2=inviato 3=approvato 4=non approvato 5=scaricato locale per aggiunta misure        
        switch(status){
          case("2"):
            this.setColors("orange")
            return " Awaiting approval"
          break;
          case("3"):
            this.setColors("green")
            return " Approved"
          break;          
          case("4"):
            this.setColors("red")
            return " Rejected"
          break;        
          case("5"):
            this.setColors("black")
            return " Local"
          break;              
        }
      },      
      cardStatus(status){
//,[fkApprovalStatus]   1=bozza 2=inviato 3=approvato 4=non approvato 5=scaricato locale per aggiunta misure        
        switch(status){
          case("2"):
            return "mdi-dots-horizontal-circle-outline"
          break;
          case("3"):
            return "mdi-emoticon-happy-outline"
          break;          
          case("4"):
            return "mdi-emoticon-sad-outline"
          break;        
          case("5"):
            return "mdi-arrow-down-bold-circle-outline"
          break;              
        }
      },
      gotoToPage(page){
        if (page!==this.$route.path) this.$router.push(page);
      },      
      viewTree(event){
        //
        event.preventDefault()
        console.log("cliccato ")
        console.log(this.tree)
        console.log("fkApproval:"+this.tree.fkApprovalStatus)
        //if ((this.localTree) && ((this.tree.fkApprovalStatus!=='5') ||  (this.tree.fkApprovalStatus== undefined) )){ //local Tree
        if ((this.tree.localTree)) {
          console.log("locale")
          if  (this.tree.fkApprovalStatus==='5'){
            console.log("è 5!")
            setTimeout(() => {
              serverBus.$emit(this.dbName,this.tree);
            }, 400);    
            this.tree.imageFilePath_imgL = this.tree.photoBlob
            serverBus.$emit("viewRemoteTree",this.tree); 
            //var idAlbero = this.tree.remoteId    
            //idAlbero = "715";
            //serverBus.$emit("viewDataAddedBefore",getLocalData(idAlbero,"treeCacheLocalAddedData")); 
            
            if (this.$store.state.logged){

              

            } else {



            }


            
          } else {
            console.log("caricatoDaLocale")
            console.log(this.tree)
            this.tree.operationDateTime = this.tree.plantationDate
            this.tree.fkTreeTypeList = this.tree.selectedTreeType
            this.$store.state.modifyTree = true
            setTimeout(() => {

              serverBus.$emit(this.dbName,this.tree);
            }, 400);
            console.log("pod")
            this.gotoToPage(this.dbPath)
          }
        } else { //remote Tree
          console.log("remoto")
          console.log(this.tree)
          this.tree.imageFilePath_imgL = this.tree.photoBlob
          serverBus.$emit("viewRemoteTree",this.tree);
        }
      }
    },
    watch: {
    },    
}

async function putLocalSingleData(dataId,data,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    set(dataId,data,customStore)
}
async function getLocalSingleData(objId,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    get(objId,customStore).then((val) => {
        console.log(val);
        return val
})}
</script>