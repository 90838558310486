<template>
<div 

>
<v-card v-show="activeScreen==='treeStory'" elevation="0">
  <tree-story
    v-bind:treeData="treeStoryDetails"
  />
</v-card>
  <v-card v-show="activeScreen==='main'" elevation="0">
        <v-card-text>
            <v-card
              elevation="0"
            >
                <v-card-title class="d-flex justify-center mt-0">
                    <h6 class="greenColor">Before you start</h6>
                </v-card-title>
                <v-card-text>
                    <v-row class="greenColor">
                        <v-col cols="4">
                            <v-icon class="d-flex justify-center mt-0" dense color="#215356">mdi-tree</v-icon> 
                            <h6 class="text-center littleLittleFonts mt-2">Are you near your tree?</h6>
                        </v-col>
                        <v-col cols="4">
                            <v-icon class="d-flex justify-center mt-0" dense color="#215356">mdi-ruler</v-icon> 
                            <h6 class="text-center littleLittleFonts mt-2">Have something to measure your tree(height and width)</h6>
                        </v-col>
                        <v-col cols="4">
                            <v-icon class="d-flex justify-center mt-0" dense color="#215356">mdi-alert-circle-outline</v-icon> 
                            <h6 class="text-center littleLittleFonts mt-2">Ask an adult if you need help</h6>
                        </v-col>                
                    </v-row>
                </v-card-text>
            </v-card> 
            <v-divider/>  
            <h6 class="greenColor littleLittleFonts mt-6">* Required fields</h6>
            <v-icon color="#215356" dense>mdi-tree</v-icon><span class="caption mt-0 greenColor"> What should we name this tree/project?* </span>
<!--
{{((modifyState) ? "modifica" : "scrivi")}} -->
            <v-text-field
                v-model="treeName"
                required
                clearable
                dense
                outlined
                color="#215356"
                class="ml-4 mr-4"
                />    
                <v-divider/>  
            <v-icon color="#215356" dense>mdi-account</v-icon><span class="caption mt-0 greenColor"> Who will be caring for this tree?*</span>
            <v-text-field
            v-model="treeOwner"
            required
            clearable
            dense
            outlined
            color="#215356"
            class="ml-4 mr-4"
            />    
            <v-divider/>  
            <v-icon color="#215356" dense>mdi-calendar</v-icon><span class="caption mt-0 greenColor"> Date you planted the tree/s*</span>
            <v-dialog
                ref="dialog"
                v-model="modalDate"
                :return-value.sync="date"
                persistent
                width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="ComputedplantationDate"
                    readonly
                    v-bind="attrs"
                    outlined
                    v-on="on"
                    dense
                    class="ml-4 mr-4"
                    color="#215356"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="plantationDate"
                color="#215356"
                @input="modalDate = false"
                /> 
            </v-dialog>
            <v-divider/>  
            <v-icon color="#215356" dense>mdi-tree</v-icon><span class="caption mt-0 greenColor"> What are you tracking?*</span>
            <v-row>
              <v-col cols="6">
                <v-card
                  height="80px"
                  :elevation="(singleTree) ? 0 : 4"
                  :color="(singleTree) ? 'teal lighten-3' : ''"
                  @click="changeSingleTreeState(true)"
                >
                  <v-card-text>
                    <v-icon class="d-flex justify-center mt-0" dense color="#215356">mdi-tree</v-icon>
                    <h6 class="text-center">Single Tree</h6>
                  </v-card-text>
                </v-card>
              </v-col>  
              <v-col cols="6">
                <v-card
                  height="80px"
                  :elevation="(!singleTree) ? 0 : 4"
                  :color="(!singleTree) ? 'teal lighten-3' : ''"
                  @click="changeSingleTreeState(false)"
                >
                  <v-card-text class="d-flex justify-center">

                    <v-icon class=" mt-0 mr-0" dense color="#215356">mdi-tree</v-icon><v-icon color="#215356" dense class="ml-0">mdi-tree</v-icon>
                    <h6 class="text-center">Batch of Trees</h6>
                  </v-card-text>
                </v-card>              
              </v-col>  
            </v-row>
            <span
              v-show="(!singleTree)"
              class="mt-6 mb-5"
            >
            <v-icon color="#215356" dense>mdi-tree</v-icon><span class="caption mt-0 greenColor"> Number of trees*</span>
            <v-text-field
            v-model="numberOfTrees"
            clearable
            dense
            outlined
            color="#215356"
            class="ml-4 mr-4"
            />    
            </span>                 
            <v-divider class="mt-3 mb-3"/>
        

            <!--<h6 class="greenColor">* Required Fields</h6>-->
            <v-icon dense color="#215356">mdi-leaf</v-icon><span class="caption mt-0 greenColor"> {{(singleTree) ? 'Tree Species*' : 'Tree Species*'}}</span><br>
          <!--  <v-btn small  class="">{{selectedTreeTypeName}}</v-btn>-->
            
 <!--            
            <v-select
                class="mt-2"
                :items="itemsTreesType"
                @change="selectTreeType"
                v-model="selectedTreeType"
                dense
                outlined
                color="#215356"       
                />   

              <p class="text-caption">{{treeTypeText}}</p>

              <v-img
              :src="treeImage"
              />

                <v-divider class="mt-3 mb-3"/>
  <v-switch 
    label="Manual Coordinates"
    v-model="manualCoordinates"
  /> -->
<div id="tagTreeTypes"/>
<tree-info
v-bind:treesData="itemsTreesType"

/>

<div class="mt-2">
<v-icon color="#215356" dense>mdi-pencil</v-icon><span class="caption mt-0 greenColor"> Select on the map where you planted your tree/s. Add a single dot or draw a shape</span></div>
              <v-card
              height="200px"
              @click="visuMap"
              >
              
                <tree-map-view-preview
                  v-bind:coordCenter="coordCenterPreview"
                  v-bind:coordPoly="coordPolyPreview"
                />
              </v-card>
              <v-divider class="mt-3 mb-3"/>
            <v-icon dense color="#215356">mdi-ruler</v-icon><span class="caption mt-0 greenColor"> Measure your tree?*</span>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                    v-model="treeHeight"
                    placeholder="Height"
                    type="number"
                    required
                    clearable
                    dense
                    outlined
                    color="#215356"
                    class="ml-1"
                    suffix="m"
                    />            
                </v-col>
                <v-col cols="6">
                    <v-text-field
                    v-model="treeWidth"
                    placeholder="Width"
                    type="number"
                    required
                    clearable
                    dense
                    outlined
                    color="#215356"
                    class="ml-1"
                    suffix="cm"
                    />            
                </v-col>          
            </v-row>
            <v-divider class="mt-3 mb-3"/>
            <span class="caption mt-0 greenColor"> Take a picture of your tree(optional)</span>
            <v-container class="d-flex justify-center mt-1" style="height:150px">  
                <v-row>
                    <v-col cols="6">
                        <v-img :src="photoHtml" max-width="200px" max-height="140px" />
                    </v-col>
                    <v-col cols="6" class="justify-center d-flex">
                      <v-col>
                      <v-row>
                        <v-btn
                            x-large
                            elevation="3"
                            color="#FFF"
                            class="greenColor ml-6"
                            @click="takePicture"
                        >
                          <v-icon color="#215356">mdi-camera</v-icon>
                        </v-btn>
                      </v-row>
                      <v-row>
                       <v-btn
                            x-large
                            elevation="3"
                            color="#FFF"
                            class="greenColor ml-6 mt-3"
                            @click="selectImage"
                        >
                          <v-icon color="#215356">mdi-camera-enhance</v-icon>
                        </v-btn>  
                       <!--
                      <v-file-input
                        prepend-icon="mdi-camera-enhance"
                        hide-input
                        hide-details
                        class="mt-3"
                        outlined
                        
                      >
                        <v-icon color="#215356">mdi-camera-enhance</v-icon></v-file-input>-->
                      <input type="file" id="selectedFile" @change="onFileSelected" style="display: none;" class="mb-4"/>                        
                      
                      </v-row>   
                      </v-col>                     
                    </v-col>
                </v-row>
            </v-container>
            <v-divider class="mt-3 mb-3"/>
        </v-card-text>   
        <v-card-actions>
          
            <v-container class="d-flex justify-center mt-1 mb-6">  
              <!--  <v-btn
                    small
                    elevation="3"
                    color="#FFF"
                    class="greenColor"
                    @click="saveDraft"
                >
                Save Draft
                </v-btn> -->       
                <v-btn
                    small
                    elevation="3"
                    color="#215356"
                    class="white--text ml-4"
                    @click="saveSend"
                >
                Save and Send
                </v-btn>
            </v-container> 
        </v-card-actions>
  </v-card> 

<div v-show="activeScreen==='camera'">

    <camera
      v-show="activeScreen==='camera'"
    />
</div>
    <v-dialog
      v-model="dialogMap"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"      
    >
    </v-dialog>
    <v-dialog
        v-model="dialogUploadOnlLine"
    >
        <v-card>
            <v-card-title class="text-h5">
                Upload to Online DB?
            </v-card-title>
            <v-card-text>Do you want to upload the tree in the cloud.</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="saveLocally"
                >
                    Disagree
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="saveOnline"
                >
                    Agree
                </v-btn>
            </v-card-actions>
        </v-card>    
    </v-dialog>
    <v-dialog
        v-model="dialogUploadOnlLineDraft"
    >
        <v-card>
            <v-card-title class="text-h5">
                Upload to Online DB?
            </v-card-title>
            <v-card-text>Do you want to upload the draft tree in the cloud.</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="saveLocallyDraft"
                >
                    Disagree
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="saveOnlineDraft"
                >
                    Agree
                </v-btn>
            </v-card-actions>
        </v-card>    
    </v-dialog>  
</div>


     <!--   </v-card-text>  
        <v-card-actions>
            <v-container class="d-flex justify-center mt-0 mb-12">  
                <v-btn
                    small
                    elevation="3"
                    color="#FFF"
                    class="greenColor"
                    @click="saveDraft"
                >
                Save Draft
                </v-btn>       
                <v-btn
                    small
                    elevation="3"
                    color="#215356"
                    class="white--text ml-4"
                    @click="nextStep"
                >
                Next
                </v-btn>
            </v-container> 
        </v-card-actions>
       </v-card>
    <v-card v-show="step===2" elevation="0"> 
        <v-card-text>-->


</template>
<style scoped>
.scroll {
   overflow-y: scroll
}
.greenColor{
  color:#215356
}
.v-text-field {
    margin: 0;
    padding: 0;
}
.littleLittleFonts{
  font-size: 10px;
  line-height: 1;
  font-weight: normal;
}

</style>
<script>

import treeMapViewPreview from '../components/treeMapViewPreview.vue'
//import treeMapView from '../components/treeMapView.vue'
import camera from '../components/camera.vue'
import toDataURL from '../common/commonFunctions'
import treeInfo from '../components/treeInfo.vue'
import treeStory from '../views/treeStory.vue'
import { serverBus } from '../main';
import { set, get,createStore,entries, del,update} from 'idb-keyval';
/*
function toDataURL(url,callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}
*/
export default {
    name: 'addNewTree',
    props:[''],
    components: {
      camera,
      treeMapViewPreview,
      treeInfo,
      //treeMapView,
      treeStory,

    },

    computed:{
      ComputedplantationDate () {
        return this.formatDate(this.plantationDate)
      },
    },
    beforeMount(){
      //prima del mount
      serverBus.$on("giveMeTreePhoto",(photoBlob) =>{
        console.log("salva blob e html")
        this.photoBlob = photoBlob
        this.photoHtml = window.URL.createObjectURL(photoBlob)  
        this.step =2   
        this.activeScreen='main'
      });
      serverBus.$on("backToMain",() =>{
        this.activeScreen='main'
        setTimeout(() => {
          this.$vuetify.goTo(document.querySelector("#tagTreeTypes"))
        }, 500);
        
      });   
      serverBus.$on("selectedTypeOfTree",(id) =>{
        this.selectedTreeType = id
      });       
      serverBus.$on("selectedTreeData",(data) =>{
        this.selectedTreeData = data
      });              
      serverBus.$on("backToNewTree",() =>{
        this.dialogMap = false
      });   
      serverBus.$on("treeStory",(data) =>{
        this.$vuetify.goTo(0)
        this.activeScreen='treeStory'
        console.log("treeStory")
        this.treeStoryDetails = data
        console.log(data)
      });          
      serverBus.$on("takePictureOffLine",(pippo) =>{
        this.step =2 
        this.activeScreen='main'
        console.log("amera"+pippo)
        console.log("camera")
        console.log(this.cameraOn)
      });
      serverBus.$on("saveCoordAndPoly",(coord,poly) =>{
        console.log("saveCoordAndPoly")
        this.coordCenterPreview = coord
        this.coordPolyPreview = poly
      });   
      serverBus.$on("compileForm",(data) =>{
        this.treeName = data.treeName
        this.treeOwner = data.owner
        this.selectedTreeType = data.fkTreeTypeList
        this.plantationDate = data.operationDateTime
        this.numberOfTrees = data.tree_nr
        this.singleTree = (parseInt(data.tree_nr)>1) ? false : true
        this.treeHeight = (data.treeHeight) ? data.treeHeight : data.height_m
        this.treeWidth = (data.treeWidth) ? data.treeWidth : data.diameter_cm
        this.photoHtml = (data.photoBlob) ? data.photoBlob : data.imageFilePath_imgL
        this.coordCenterPreview = JSON.parse(data.location)
        this.coordPolyPreview = JSON.parse(data.locationSelection)
        this.modifyState = this.$store.state.modifyTree
        this.syncId = data.syncId
      });     
      serverBus.$on("compileFormNew",(data) =>{
        this.treeName = data.treeName
        this.treeOwner = data.owner
        this.selectedTreeType = data.fkTreeTypeList
        this.plantationDate = data.operationDateTime
        this.numberOfTrees = data.tree_nr
        this.singleTree = (parseInt(data.tree_nr)>1) ? false : true
        this.treeHeight = (data.treeHeight) ? data.treeHeight : data.height_m
        this.treeWidth = (data.treeWidth) ? data.treeWidth : data.diameter_cm
        this.photoHtml = (data.photoBlob) ? data.photoBlob : data.imageFilePath_imgL
        this.coordCenterPreview = JSON.parse(data.location)
        this.coordPolyPreview = JSON.parse(data.locationSelection)
        this.modifyState = false
        this.syncId = data.syncId
      });                      
    },   
    mounted(){
      this.getTreeTypeListLocallyWithDetails()
      console.log(this.$store.state.userData)
      this.treeOwner = this.$store.state.userData.firstName+" "+this.$store.state.userData.lastName
      console.log("random:"+this.makeid(300))
      //serverBus.$emit("updateMapPointPreview");
    },    
    data:()=> ({
        plantationDate:new Date().toISOString().substr(0, 10),
        treeName:null,
        treeOwner:null,
        selectedTreeType:null,
        selectedTreeData:null,
        treeHeight:null,
        treeWidth:null,
        singleTree:true,
        numberOfTrees:null,
        syncId:null,

        modalDate:false,
        step:1,
        cameraOn:false,
        photoBlob:null,
        photoHtml:null,
        date:null,
        itemsTreesType:[],
        
        modifyState:false,

        //dialog
        dialogUploadOnlLine:false,
        dialogUploadOnlLineDraft:false,
        dialogMap:false,

        //preview Map
        coordCenterPreview:[45.480938300000005,10],
        coordPolyPreview:[],

        manualCoordinates:false,

        selectedTreeTypeName:"Selected Tree",
        treeTypeText:null,
        treeImage:null,

        //activeScreen
        activeScreen: "main",
        treeStoryDetails:[],

        //upload file
        selectedFile:[],
        selectedFileSize:null,
        selectedFileTypo:null,


    }),
    methods: {
      caricaFileSelezionato(){
            //this.selectedFile.name Nome del file
            //prepara form data
            console.log("sel:"+this.selectedCompany);
            const fd = new FormData();
            fd.append("image",this.selectedFile, this.selectedFile.name);
            fd.append("typoFile",this.selectedFileTypo);
            fd.append("fileSize",this.selectedFileSize);
            fd.append("selectedCompany",this.selectedCompany);//mettere l'id dell'azienda selezionata
            fd.append("app","companyMgm");

            //console.log("imma:");
            for (var value of fd.keys()) {
                    console.log(value); 
                }  
            console.log(fd)

    },           
        onFileSelected(event){
            this.selectedFile = event.target.files[0];
            this.selectedFileSize = event.target.files[0].size;
            this.selectedFileTypo = event.srcElement.id;

            this.photoHtml = window.URL.createObjectURL(this.selectedFile)  

            console.log("file Selected")
            //this.caricaFileSelezionato();
            console.log(event);
      },      
        selectImage(){

          document.getElementById('selectedFile').click();
        },
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },      
      getFormData(){
        let data = { treeName: this.treeName,
                    owner: this.treeOwner, 
                    fkTreeTypeList: this.selectedTreeType, 
                    fkUser:this.$store.state.userData.id,
                    operationDateTime: this.plantationDate,
                    fkApprovalStatus:"2",
                    tree_nr:(this.numberOfTrees) ? this.numberOfTrees :"1",
                    height_m:this.treeHeight, 
                    diameter_cm: this.treeWidth, 
                    imageFilePath_imgL:this.photoHtml, 
                    location:JSON.stringify(this.coordCenterPreview),
                    locationSelection:JSON.stringify(this.coordPolyPreview),
                    syncId:this.syncId
                  };
        this.$store.state.openedTree = data
        console.log("getFormData")
        console.log(data)
      },
      visuMap(){
        this.gotoToPage("/treeMapView")
        //this.dialogMap = true
        console.log("visuMap")
        setTimeout(() => {
          //serverBus.$emit("updateMapPoint",this.manualCoordinates); 
          this.getFormData()
        }, 1000);
        //serverBus.$emit("updateMapPoint");
      },
      changeSingleTreeState(state){
        this.singleTree = state
      },
      getTreeTypeListLocally(){
        const customStore = createStore("localTreeTypesList", "localTreeTypesList");
        entries(customStore).then((entries) => {
          console.log("getTreeTypeList")
          console.log(entries)
          entries.forEach(oneElement => {
            var element = oneElement[1]
            var data = {  
                value:element.id,
                text:element.text,
              }
              this.itemsTreesType.push(data)
          })
          });     
      },
      getTreeTypeListLocallyWithDetails(){
        const customStore = createStore("localTreeTypesListDetails", "localTreeTypesListDetails");
        entries(customStore).then((entries) => {
          console.log("getTreeTypeList")
          console.log(entries)
          entries.forEach(oneElement => {
            var element = oneElement[1]
            this.itemsTreesType.push(element)
          })
          });     
      },      
      createLocalTreeListWithData(dbName){
        var ritornoData = []
        let request = window.indexedDB.open(dbName, 1);
        request.onerror = function() {
            console.log('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
          
            console.log('Database opened successfully');

            // Store the opened database object in the db variable. This is used a lot below
            var db = request.result;

            console.log("result")
            console.log(db)

            // open a read/write db transaction, ready for adding the data
            let transaction = db.transaction([dbName]);
            // call an object store that's already been added to the database
            let objectStore = transaction.objectStore(dbName);  
            
            objectStore.openCursor().onsuccess = function(event) {
            var cursor = event.target.result;
            var indice = 0
            if (cursor) {
              var data = 
                {  value:cursor.value.id,
                  text:cursor.value.text,

                }

              ritornoData.push(data)
              console.log(data)
              //ritornoData[] = data
              cursor.continue();

            }
            else {

            }
            
            
            };

            let request2 = objectStore.get("");

            request2.onsuccess = function(event) {

            console.log('Richiesta creazione OK.');
            console.log(event)
            };

            transaction.oncomplete = function() {
            };

            transaction.onerror = function() {
            console.log('Recupero fallito');
            };   
        };      
        console.log("finito alberi lista locali");
        console.log(ritornoData)
        this.tempRemoteUpload = ritornoData
        return ritornoData
      },
      saveLocallyDraft(){
        console.log("local")
        let data = {treeName: this.treeName, treeOwner: this.treeOwner, selectedTreeType: this.selectedTreeType, treeHeight:this.treeHeight, treeWidth: this.treeWidth, plantationDate: this.plantationDate,photoBlob: this.photoBlob,syncId:this.makeid(200)}        
          //this.saveDataToLocalDb(data,"treeCacheDraft")
          putLocalSingleData(data.syncId,data,"treeSavedLocallyDraft","treeSavedLocallyDraft")
          setTimeout(() => {
            this.dialogUploadOnlLine =false
            this.gotoToPage("/userHP")
          }, 500);           
      },     
      saveLocally(){
        console.log("local")
        //let data = { treeName: this.treeName, treeOwner: this.treeOwner, selectedTreeType: this.selectedTreeType, treeHeight:this.treeHeight, treeWidth: this.treeWidth, plantationDate: this.plantationDate,photoBlob: this.photoBlob,syncId:this.makeid(200) };        

        let data = { 
          treeName: this.treeName,
          owner: this.treeOwner, 
          fkTreeTypeList: this.selectedTreeType, 
          fkUser:this.$store.state.userData.id,
          operationDateTime: this.plantationDate,
          fkApprovalStatus:"2",
          tree_nr:(this.numberOfTrees) ? this.numberOfTrees :"1",
          height_m:this.treeHeight, 
          diameter_cm: this.treeWidth, 
          imageFilePath_imgL:this.photoBlob, 
          location:JSON.stringify(this.coordCenterPreview),
          locationSelection:JSON.stringify(this.coordPolyPreview),
          syncId:(this.syncId) ? this.syncId : this.makeid(200)
        };


        console.log(data)
          if (this.modifyState){
            updateSingleData(this.syncId,data,"treeSavedLocally","treeSavedLocally")
          } else {
            putLocalSingleData(data.syncId,data,"treeSavedLocally","treeSavedLocally")
          }
          
          //this.saveDataToLocalDb(data,"treeCache")
          setTimeout(() => {
            this.dialogUploadOnlLine =false
            this.gotoToPage("/userHP")
            setTimeout(() => {
              serverBus.$emit("snackbarGeneric","Saved Locally");
            }, 1000);
            
          }, 500);           
      },      
      saveOnlineDraft(){
        console.log("online")
        // userId treeName treeOwner treeType treeHeight treeWidth plantationDate photoBlob syncId flagDraft
        let data = { treeName: this.treeName, treeOwner: this.treeOwner, selectedTreeType: this.selectedTreeType, treeHeight:this.treeHeight, treeWidth: this.treeWidth, plantationDate: this.plantationDate,photoBlob: this.photoBlob,syncId:this.makeid(200) };
        var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
            method: 'post',
            url: 'trees/saveTree',
            data: {
            lok:locSto,
            tree: data,
            }
            }).then((response) => {
                console.log(response.data)
                 this.dialogUploadOnlLineDraft =false
                 this.gotoToPage("/userHP")
            });     
      },          
      saveOnLineWithBlob(dataBlob){
          // userId treeName treeOwner treeType treeHeight treeWidth plantationDate photoBlob syncId flagDraft
          /*
          let data = { treeName: this.treeName,
                      owner: this.treeOwner, 
                      fkTreeTypeList: this.selectedTreeType, 
                      fkUser:this.$store.state.userData.id,
                      fkOperationType:"1",
                      fkTypeOfSubmission:"1",
                      operationDateTime: this.plantationDate,
                      fkApprovalStatus:"2",
                      tree_nr:"1",
                      height_m:this.treeHeight, 
                      diameter_cm: this.treeWidth, 
                      imageFilePath_imgL:dataBlob
                    };*/
          let data = { treeName: this.treeName,
                      owner: this.treeOwner, 
                      fkTreeTypeList: this.selectedTreeType, 
                      fkUser:this.$store.state.userData.id,
                      operationDateTime: this.plantationDate,
                      fkOperationType:"1",
                      fkTypeOfSubmission:"1",                      
                      fkApprovalStatus:"2",
                      tree_nr:(this.numberOfTrees) ? this.numberOfTrees :"1",
                      height_m:this.treeHeight, 
                      diameter_cm: this.treeWidth, 
                      imageFilePath_imgL:dataBlob, 
                      location:JSON.stringify(this.coordCenterPreview),
                      locationSelection:JSON.stringify(this.coordPolyPreview),
                    };
          var locSto = localStorage.getItem("tok");
          console.log("loc:"+locSto)
          this.axios({
          method: 'post',
          url: 'trees/saveTree',
          data: {
            tok:locSto,
            tree: data,
          }
          }).then((response) => {
  //
             this.dialogUploadOnlLine =false
             this.gotoToPage("/userHP")
             setTimeout(() => {
              serverBus.$emit("snackbarGeneric","Saved Remotely"); 
             }, 1000);
             
            console.log(response.data)
          });  
      },
      saveOnline(){
        console.log("online")
//  
        console.log(this.photoHtml)
        toDataURL(this.photoHtml, this.saveOnLineWithBlob)
      },
        makeid(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
        charactersLength));
            }
            return result;
        },
      getTreeTypeList(){

          this.axios({
            method: 'get',
            url: 'trees/treeTypeList'
            }).then((response) => {
                //console.log(response.data)
                this.itemsTreesType = response.data
                console.log(response.data)
            });  

      },
      saveSend(){
        if (this.$store.state.OnLine) {
          this.saveOnline()
        } else{
          this.saveLocally()
        }
      },
        gotoToPage(page){
        if (page!==this.$route.path) this.$router.push(page);
      },   
      /*   
      saveDataToLocalDb(data,dbName){
        let request = window.indexedDB.open(dbName, 1);
        request.onerror = function() {
          console.log('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
          console.log('Database opened successfully');

          // Store the opened database object in the db variable. This is used a lot below
          var db = request.result;

          // open a read/write db transaction, ready for adding the data
          let transaction = db.transaction([dbName], 'readwrite');
          // call an object store that's already been added to the database
          let objectStore = transaction.objectStore(dbName);         
          // Make a request to add our newItem object to the object store
          let request2 = objectStore.add(data);
          request2.onsuccess = function() {
            // Clear the form, ready for adding the next entry

          };

          // Report on the success of the transaction completing, when everything is done
          transaction.oncomplete = function() {
            console.log('Transaction completed: database modification finished.');

            // update the display of data to show the newly added item, by running displayData() again.
            //displayData();
          };

          transaction.onerror = function() {
            console.log('Transaction not opened due to error');
          };   
        };  
      },*/
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      nextStep(){
        if (this.step===1){
            if ((this.treeName===null) || (this.treeName==="")) {
                return
            }
        }
        (this.step<2) ? this.step++ : this.step;
        setTimeout(() => {
          serverBus.$emit("updateMapPoint");
        }, 500);
        
      },
      takePicture(){

        this.gotoToPage("/camera")
        console.log("takePicture")
        setTimeout(() => {
          this.getFormData()
        }, 1000);        
      },
      saveDraft(){
        //
        if (this.$store.state.OnLine) {
          this.dialogUploadOnlLineDraft =true
        } else{
        let data = { treeName: this.treeName, treeOwner: this.treeOwner, selectedTreeType: this.selectedTreeType, treeHeight:this.treeHeight, treeWidth: this.treeWidth, plantationDate: this.plantationDate,photoBlob: this.photoBlob,syncId:this.makeid(200) };          
          putLocalSingleData(data.syncId,data,"treeSavedLocallyDraft","treeSavedLocallyDraft")
          setTimeout(() => {
            this.gotoToPage("/userHP")
          }, 500);          
        }

      },
      selectOrg(){
        //
      },
      selectTreeType(value){
        this.selectedTreeType = value
        console.log("valoreTypo:"+value)

        const customStore = createStore("localTreeTypesListDetails", "localTreeTypesListDetails");
        get(value,customStore).then((val) => {
          console.log("albero::::")
          console.log(val)
          this.treeTypeText = val.description_EN
          this.treeImage = "../assets/trees/"+val.fileName.trim()+".jpg"
          console.log("fileNameTree:"+this.treeImage)
        });  


      }
    },
    watch: {
      plantationDate(value){
        console.log(value)
      }
    },     




}


async function putLocalSingleData(dataId,data,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    console.log("ADD:"+dataId)
    set(dataId,data,customStore)
}
async function getLocalSingleData(objId,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    get(objId,customStore).then((val) => {
        console.log(val);
        return val
})}
async function updateSingleData(objId,newData,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    console.log("**UPDATE:"+objId)
    update(objId, newData,customStore);
}
async function getAllEntries(dbName,storeName){
    const customStore = createStore(dbName, storeName);
    entries(customStore).then((entries) => console.log(entries));
}
async function delSingleData(objId,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    del(objId,customStore);
}


</script>





