<template>
  <div>
    <v-img
      src="../assets/logoImage1.png"
      height="200"
      contain
    />
    <v-form
      class="justify-center"
    >
      <v-container>
        <v-row>
          <v-col cols="0"
          sm="0"
          md="0"
          xs="0"
          lg="4"
          />
          <v-col  cols="12"
          sm="12"
          md="12"
          xs="12"
          lg="4">
            <v-text-field
              v-model="username"
              label="Username"
              required
              clearable
            />
            <v-text-field
              v-model="password"
              label="Password"
              required
              clearable
              :append-icon="pwdShow ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[passwordRules.required, passwordRules.min]"
              :type="pwdShow ? 'text' : 'password'"
              @click:append="pwdShow = !pwdShow"
            />
            <v-container class="d-flex justify-center mt-1">  
              <v-btn
                small
                elevation="3"
                color="#215356"
                class="white--text"
                @click="login"
                :disabled="buttonStatus"
              >
                Log In
              </v-btn>
            </v-container>  
            <v-container class="d-flex justify-center mt-0">  
              <p class="text-center text-subtitle-2 mr-2" @click="forgotPassword">
                <span class="greenColor"> Forgot Password </span>
  </p>
 or  
              <p class="text-center text-subtitle-2 ml-2 " @click="requestAccess">
                <span class="greenColor"> Request Access </span>
              </p>               
            </v-container>  
          </v-col>
        </v-row>
      </v-container>   
    </v-form>  
    <v-dialog
      v-model="dialogErrorLogin"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-icon @click="dialogErrorLogin=false">mdi-close</v-icon>
        <v-card-title>Login Error</v-card-title>
        <v-card-text>     
          {{errorTextMessage}}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
    name: 'Login',
    props:[''],
    components: {
      
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
    },    
    mounted(){
      //dopo il mount
    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        username:null,
        password:null,
        buttonStatus:true,
        pwdShow:false,
        passwordRules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },   
        dialogErrorLogin:false,
        errorTextMessage:"",       
    }),
    methods: {
      gotoToPage(page){
          if (page!==this.$route.path) this.$router.push(page);
      },           
      checkForButton(){
        this.buttonStatus = !(this.username!==null && this.password!==null)
        console.log(this.buttonStatus)
      },      
      login(){
// var locSto = localStorage.getItem("tok");  localStorage.setItem("tok",response.data.tok);
        var req = {
            username: this.username,
            pwd: this.password,
        }
        console.log(req)
        this.axios({
        method: 'post',
        url: 'auth/loginUtente',
        data: req
  
        }).then((response) => {
            //console.log(response.data)
            if (response.data.logged){
              localStorage.setItem("tok",response.data.tok);
              this.$store.state.logged = response.data.logged;
              this.$store.state.userData = response.data.userData;
              setTimeout(() => {
                this.gotoToPage("/userHP")
              }, 300);
            } else {
              console.log(response.data)
              switch(response.data.errorType){
                case("NoPWDUSR"):
                  this.errorTextMessage = "Wrong username or password try again"
                  this.dialogErrorLogin = true
                break
                case("NotExist"):
                  this.errorTextMessage = "User is not present"
                  this.dialogErrorLogin = true
                break
                case("tooManyTentative"):
                  this.errorTextMessage = "Your account as been locked, ask your administrator to unlock your account or follow the 'Lost Password' procedure"
                  this.dialogErrorLogin = true
                break
                case("locked"):
                  this.errorTextMessage = "Your account as been locked, ask your administrator to unlock your account or follow the 'Lost Password' procedure"
                  this.dialogErrorLogin = true
                break                
              }
            }
            console.log(response.data)
        });         
      },
      forgotPassword(){
//
      this.gotoToPage("/pwdRst")
      },
      requestAccess(){
//
      this.gotoToPage("/reqAcc")
      }
    },    
    watch: {
      username(){
          this.checkForButton()
      },
      password(){
          this.checkForButton()
      },      
    },    
}
</script>