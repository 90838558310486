<template>
<v-img
  height="100%"
  width="100%"
  class="pt-0 pb-8 pl-1 pr-1"
>
    <l-map 
        :zoom="zoom" 
        :center="center"
        @click="cliccasumappa"
        ref="mappa"
        @update:zoom="zoomUpdated"
    > <!--       -->
   <!-- <l-control-zoom position="bottomright"  ></l-control-zoom>   -->
        <l-circle
            :lat-lng="cerchio.coords"
            :radius="circle.radius"
            :color="circle.color"
            @click="cliccaCerchio"
            v-for="cerchio in cerchi"
            :key="cerchio.id"
            interactive
        />    
             
        <l-tile-layer :url="url"></l-tile-layer>
        <l-marker 
            :lat-lng="markerLatLng" 
            visible
        ></l-marker>
        <l-polygon :lat-lngs="polygon.latlngs" :color="polygon.color"></l-polygon>

        <!-- <l-geo-json :geojson="geojson"></l-geo-json> -->
        <l-control disableClickPropagation v-show="!readOnlyMap">
      <!--  <span class="">{{(manualCoordinates) ? "Manual" : ""}}</span> -->
        <v-btn 
        icon 
        @click="getCurrentPositionNow" 
        x-small 
        fab
        v-show="(!readOnlyMap)" 
        dark
        class="mr-2"
        color="primary">
        <v-icon>mdi-map-marker-radius-outline</v-icon>
        </v-btn>      
        <v-btn @click="setMarkerNow" x-small v-show="(!readOnlyMap)" :color="(setMarkerManually) ? 'red' :''" class="mr-2">
        Manual
        </v-btn>
        <v-btn @click="clearMap" x-small v-show="!readOnlyMap">
        Clear
        </v-btn>
        <v-btn @click="acceptMap" x-small class="ml-1" v-show="!readOnlyMap">
        OK
        </v-btn>  
        <v-btn @click="exitMap" x-small class="ml-1" v-show="!readOnlyMap">
        Back
        </v-btn>               
        </l-control>

    </l-map>
</v-img>

</template>

<script>

//import selectAreaFeature from 'leaflet-selectareafeature'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
import "leaflet/dist/leaflet.css"
import { serverBus } from '../main';
import {LMap, LTileLayer, LMarker,LPolygon,LCircle,LGeoJson,LControl,LControlZoom} from 'vue2-leaflet';
export default {
    name: 'templateName',
    props:[''],
    components: {
        LMap,
        LTileLayer,
        LGeoJson,
        LMarker,
        LPolygon,
        LCircle,
        LControl,
        LControlZoom
        //selectAreaFeature
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
      /*
    const watchID = navigator.geolocation.getCurrentPosition((position) => {
      console.log("aggiornaPosizione")
      this.center=[position.coords.latitude,position.coords.longitude]
      this.markerLatLng=[position.coords.latitude,position.coords.longitude]
    });*/
    },
    async created () {
        /*
        const response = await fetch('https://rawgit.com/gregoiredavid/france-geojson/master/regions/pays-de-la-loire/communes-pays-de-la-loire.geojson');
        this.geojson = await response.json();
        */
    },
    beforeMount(){
      //prima del mount

      serverBus.$on("updateMapPointView",(puntoCentrale,poligono) =>{
            console.log("updateMapPointView")
            console.log(puntoCentrale)
            console.log("updateMapPointViewPOly")
            console.log(poligono)            
            this.center=[puntoCentrale.latitude,puntoCentrale.longitude]
            this.markerLatLng=[puntoCentrale.latitude,puntoCentrale.longitude]
            this.polygon.latlngs = poligono.latlng
      });   



      serverBus.$on("updateMapPoint",(manualCoordinates) =>{
 
        console.log("coords update")
        navigator.geolocation.getCurrentPosition(position => {
            console.log(position)
            this.center=[position.coords.latitude,position.coords.longitude]
            this.markerLatLng=[position.coords.latitude,position.coords.longitude]
        },error =>{
          console.log("ERRORECoords:"+error.code+" "+error.message)
        },{
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
        )  
        setTimeout(() => {
          console.log("manualCoordinates::")
          console.log(manualCoordinates)
          this.manualCoordinates = manualCoordinates
        }, 3000);        
        
        }); 
       /*
      const watchID = navigator.geolocation.watchPosition((position) => {
        console.log("aggiornaPosizione")
        this.center=[position.coords.latitude,position.coords.longitude]
        this.markerLatLng=[position.coords.latitude,position.coords.longitude]
      });                   
      

    const watchID = navigator.geolocation.watchPosition((position) => {
      console.log("aggiornaPosizione")
      this.center=[position.coords.latitude,position.coords.longitude]
      this.markerLatLng=[position.coords.latitude,position.coords.longitude]
    });*/
    },    
    mounted(){

      //dopo il mount
      /*
      this.$nextTick(() => {
        this.$refs.mappa.mapObject.getZoom();
      });*/
/*
    setInterval(() => {
      console.log("getPos")
        navigator.geolocation.getCurrentPosition(position => {
            console.log(position)
            this.center=[position.coords.latitude,position.coords.longitude]
            this.markerLatLng=[position.coords.latitude,position.coords.longitude]
        },error =>{
          console.log("ERRORE:"+error.code+" "+error.message)
        },{
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0
        })        
    }, 500);  

    this.clearMap()
    const watchID = navigator.geolocation.watchPosition((position) => {
      console.log("aggiornaPosizione")
      this.center=[position.coords.latitude,position.coords.longitude]
      this.markerLatLng=[position.coords.latitude,position.coords.longitude]
    });

    console.log("watchID")
    console.log(watchID)
*/
    //var mappa = this.$refs.mappa.mapObject
    //console.log(mappa)
    //var selectfeature = mappa.selectAreaFeature.enable();    

    },

    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
      //console.log("update")
        navigator.geolocation.getCurrentPosition(position => {
            console.log(position)
            this.center=[position.coords.latitude,position.coords.longitude]
            this.markerLatLng=[position.coords.latitude,position.coords.longitude]
        },error =>{
          console.log("ERRORE:"+error.code+" "+error.message)
        },{
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        })         
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 18,
        center: [45.480938300000005,10],
        markerLatLng: [45.480938300000005,10],
        geojson: null, 
        polygon: {
            latlngs: [],
            color: 'green'
        }, 
        circle: {
            center: [],
            radius: 2,
            color: 'white'
        },
        cerchi:[],
        moveCircle:false,
        circleArray:0,
        coordMove:[],  
        map:null,   
        ciao:null,
        readOnlyMap:false,
        manualCoordinates:false,   
        setMarkerManually:false,  
    }),
    methods: {
      getCurrentPositionNow(){
        serverBus.$emit("updateMapPoint",this.manualCoordinates);
      },
    setMarkerNow(){
      this.clearMapPoint()
      this.setMarkerManually = true
    },      
        gotoToPage(page){
          if (page!==this.$route.path) this.$router.push(page);
        },         
        zoomUpdated(){
          var radius = 2
          switch(this.$refs.mappa.mapObject.getZoom()){
            case(18):
            case(16):
              radius = 2
              break
            case(14):
            case(12):
              radius = 3
              break
            case(10):
            case(8):
              radius = 4  
              break                        
          }
          this.circle.radius = radius
          console.log("zoom:"+this.$refs.mappa.mapObject.getZoom())
        },
        visuMap(){

        },
        acceptMap(){
          //console.log(this.polygon.latlngs)
          console.log(this.$refs.mappa.mapObject.getZoom())
          setTimeout(() => {
            var data =this.$store.state.openedTree
            serverBus.$emit("compileForm",data)
            serverBus.$emit("saveCoordAndPoly",this.markerLatLng,this.polygon.latlngs);
            serverBus.$emit("updateMapPointPreview",this.markerLatLng,this.polygon.latlngs,this.$refs.mappa.mapObject.getZoom());
            
          }, 500);
          
          this.gotoToPage("/addNewTree")
        },
        exitMap(){
          this.gotoToPage("/addNewTree")
        },
        clearMap(){
            this.polygon.latlngs = []
            this.cerchi = []
            this.circleArray = 0
            serverBus.$emit("updateMapPoint");
        },
        clearMapPoint(){
            this.polygon.latlngs = []
            this.cerchi = []
            this.circleArray = 0


        },        
        cambia(value){
            console.log(value)
            this.markers[2] = value
        },
        cliccaMarker(val){
            this.markerLatLng = val.latlng
            setTimeout(() => {
              this.setMarkerManually = false
            }, 300);
            
            this.cerchi = []
            this.polygon.latlngs = []
        },
        cliccaNellaMappa(val){
            console.log(val)
            if (!this.moveCircle){
                this.polygon.latlngs.push(val.latlng)
                this.cerchi.push({coords:val.latlng,id:this.circleArray++})
                this.moveCircle = false
                console.log("cerchio")
            } else {
                
            }
        },
        cliccasumappa(val){
          console.log("clicca su mappa")
          if (this.setMarkerManually) {
            this.cliccaMarker(val)

          } else {
            this.cliccaNellaMappa(val)


          }

            console.log(this.polygon.latlngs)
        },
        cliccaCerchio(val){
          if (!this.setMarkerManually)
{            console.log("cliccatoCerchio")
            this.coordMove = val.latlng
            //this.moveCircle = true
            console.log(this.cerchi)}
        },

    },
    watch: {

    },    
}
</script>