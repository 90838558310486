<template>
  <div class="mt-6">
    <div class="wrapper">
      <video ref="video" v-show="shoot" class="absolute h-full inset-0 object-cover w-full z-10 mr-3" autoplay muted playsinline
      @click="capturePhoto" height="400"
      ></video>
      <v-img v-show="!shoot&&shooted" :src="imgHtml" height="400" width="320" class="absolute h-full inset-0 object-cover w-full z-10 mr-3 mt-12 ml-0" ></v-img>
      <!-- Camera -->
      <!-- ---------- -->
      <!-- Allow the user to capture photos and take other camera actions. -->
      <div class="playpause">

      </div>

    </div>
    <div v-show="shoot" class="center">
      <v-btn class="capture" @click="capturePhoto"> <v-icon>mdi-camera</v-icon></v-btn>
      <v-btn @click="closePhoto"><v-icon>mdi-arrow-left</v-icon></v-btn>
    </div>
    <div v-show="shooted" height="400" class="playpause">
      <v-btn class="capture" @click="okPhoto" > Save </v-btn>
      <v-btn @click="closePhoto"> Cancel </v-btn>
      <v-btn @click="startCamera"> Retake </v-btn>        
    </div>
  </div>
</template>
<style scoped>
.wrapper{
    display:table;
    width:auto;
    position:relative;

}
.playpause {
    width:50%;
    height:50%;
    position:absolute;
    left:0%;
    right:0%;
    top:0%;
    bottom:0%;
    margin:auto;
    background-size:contain;
    background-position: center;
}


  html, body, main, section{
    height: 100%;
    width: 100%;
  }
  
  html{
    position: fixed;
  }
  
  body{
    font-family: 'Lato', sans-serif;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
  }
  
  button.capture:disabled{
    opacity: 0.25;
  }
  
  button.capture:active{
    opacity: 0.9;
  }

</style>

<script>
const width = (window.innerWidth/3)*2;
const height = window.innerHeight-100;

import loadImage from 'blueimp-load-image'
import { serverBus } from '../main';

export default {
    name: 'camera',
    props:[''],
    components: {
      
    },    
  data() {
    return {
      stream: null,
      ready: false,
      photo: null,
      //konva
      stageSize: {
          width: 300,
          height: 400
      },
      shoot:true,    
      shooted:false, 
      imgBlob:null, 
      imgHtml:null,
    }
  },
  mounted(){
      this.startCamera();

    },
  methods: {

    async startCamera() {
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment'
        }
      })

      let video = this.$refs.video
      
      video.srcObject = this.stream
      
      video.onloadedmetadata = (e) => {
        this.ready = true
      }
      
      video.onended = (e) => {
        this.ready = false
        this.stream = null
      }

      setTimeout(() => {
       console.log(this.ready)
      console.log(this.stream)         
      }, 1000);
    },
    capturePhoto() {
      console.log("cattura Foto")
      let video = this.$refs.video
      
      let videoCanvas = document.createElement('canvas')      
      videoCanvas.height = video.videoHeight
      videoCanvas.width = video.videoWidth      
      let videoContext = videoCanvas.getContext('2d')
      
      videoContext.drawImage(video, 0, 0)
      
      this.photo = loadImage.scale(videoCanvas, {
        maxHeight: 1080,
        maxWidth: 1080,
        cover: true,
        crop: true,
        canvas: true
      })
      setTimeout(() => {
        this.shoot = false
        this.shooted = true
          
      }, 300);      
       this.photo.toBlob(blob => {
       this.imgHtml = window.URL.createObjectURL(blob)
       this.imgBlob = blob
      }, 'image/jpeg')

    },
    ciccio(){
      console.log(this.imgBlob,this.imgHtml)
    },
    okPhoto(){
      this.shoot=true
      this.shooted=false
      serverBus.$emit("giveMeTreePhoto",this.imgBlob);
    },
    closePhoto(){
      console.log("clo")
      serverBus.$emit("takePictureOffLine","ppppp")
      console.log("ciao")
    },    
    downloadPhoto() {
      this.photo.toBlob(blob => {
        let data = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.href = data
        link.download = "photo.jpg"
        link.click()
      }, 'image/jpeg')
    }
  }
}
</script>

