<template>
  <div>
    <v-btn
      class="ma-0"
      outlined
      color="#215356"
      block
      v-show="treeTypeSelectionButton"
      @click="selectTreeTypeBtn"
    >
      <v-row v-if="!treeIsSelected">
        <v-col cols="10">
          Select Type of Tree
        </v-col>
        <v-col cols="2">
          <v-icon dense color="#215356"> mdi-chevron-down</v-icon>
        </v-col>          
      </v-row>
      <v-row v-else>
        <v-col cols="2">
          <v-img
            class="rounded-circle"
            :src="'data:image/png;base64,'+selectedTreeTypeImage"
          />
        </v-col>
        <v-col cols="8">
          <span class="ml-0 littleFonts13 mr-0">
            {{((selectedTreeTypeText.length>16) ? selectedTreeTypeText.slice(0,18)+".." : selectedTreeTypeText)}}
          </span>
        </v-col> 
        <v-col cols="1">
          <v-icon dense color="#215356"> mdi-chevron-down</v-icon>
        </v-col>  
           
      </v-row>      
    </v-btn>
    <v-card
      elevation="0"
      v-show="!treeTypeSelectionButton"
      height="400px"
      flat
      rounded
      class="borders scroll"
      
    >
      <v-card
        v-for="tree in treesData"
        :key="tree.id"
        elevation="0"
        class="bordersBottom"
        :rounded="false"
      >
        <v-card-text>
        <single-tree-info
          v-bind:treeInfo="tree"
        />
        </v-card-text>

      </v-card>
    
    </v-card>
  </div>
</template>
<style scoped>
.borders {
    background-color: #ffffff !important;
    border: 1px solid #215356 !important
}
.bordersBottom {
    background-color: #ffffff !important;
    border-bottom: 1px solid #215356 !important
}
.scroll {
   overflow-y: scroll
}
.littleFonts13{
    font-size: 10px;
    line-height: 1;
    font-weight: normal;
    margin: 0;
    padding: 0;
}
</style>
<script>
import singleTreeInfo from '../components/singleTreeInfo.vue'
import { serverBus } from '../main';
export default {
    name: 'treeInfo',
    props:['treesData'],
    components: {
      singleTreeInfo
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
      serverBus.$on("selectThisTree",(data) =>{
        console.log("selectThisTree")
        this.selectedTreeTypeImage = data.mainImageFilePath_imgS
        this.selectedTreeTypeText = (data.commonName) ? data.commonName : data.scientificName
        this.treeTypeSelectionButton = true
        this.treeIsSelected = true
        serverBus.$emit("selectedTypeOfTree",data.id);
      });        
    },    
    mounted(){
      //dopo il mount
    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
      //tree type selection buttons
      treeTypeSelectionButton:true,   
      treeIsSelected:false,

      //selected Tree
      selectedTreeTypeImage:null,
      selectedTreeTypeText:"",

    }),
    methods: {
      selectTreeTypeBtn(){
        this.treeTypeSelectionButton = !this.treeTypeSelectionButton
      },

    },
    watch: {
      treesData(){

      }
    },    
}
</script>