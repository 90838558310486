<template>
<div></div>
</template>
<script>
import { serverBus } from '../main';
import { set, get,createStore,entries, del} from 'idb-keyval';
export default {
    name: 'mainTasks',
    props:[''],
    components: {
      
    },
    /*
    computed(){
      //computati
    },*/
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
      serverBus.$on("setState",(status) =>{
        //console.log("state:"+status)
        this.$store.state.OnLine = status
        //console.log("varia:"+this.$store.state.OnLine)
      });        
    }, 
    beforeMount(){
      //prima del mount
    },    
    mounted(){
        //dopo il mount
        console.log("*********************************************************************")

        if ((this.$route.path==='/userCheck')||(this.$route.path==='/pwdRst')){
          
        } else {
        var connected = this.checkConnection()
        console.log("connected")
        console.log(connected)
        setTimeout(() => {
          if (connected){
            console.log("checkIfLogged")
            this.checkIfLogged()
          } else {
            //no internet
            

          const customStore = createStore("userData", "userData");
          entries(customStore).then((entries) => {
            console.log("getAllLocalTrees")
            console.log(entries)

/*


            entries.forEach(oneElement => {
              var element = oneElement[1]
              var photoHtml = (element.imageFilePath_imgL!==null) ? window.URL.createObjectURL(element.imageFilePath_imgL) : null
              var data = {  treeName:element.treeName,
                photoBlob:photoHtml,
                realBlob:element.imageFilePath_imgL,
                localTree:true,
                owner:element.owner,
                syncId:element.syncId,
                plantationDate:element.operationDateTime,
                selectedTreeType:element.fkTreeTypeList,
                treeHeight:element.height_m,
                treeWidth:element.diameter_cm,
                id:element.id,
                location:element.location,
                locationSelection:element.locationSelection,
                tree_nr:element.tree_nr
                }
                this.localTrees.push(data)
            })

            */
            });


            setTimeout(() => {
              if (this.$route.path==='/') this.gotoToPage("/userHP")
            }, 300);            
          }
        }, 200);
        

        this.checkConActions(10) 
}    
    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        
    }),
    methods: {
    checkIfLogged(){
    if (this.$store.state.loggato===undefined) {
      var locSto = localStorage.getItem("tok");
      console.log("loc:"+locSto)
      this.axios({
      method: 'post',
      url: 'auth/loggato',
      data: {
        lok:locSto
      }
      }).then((response) => {
        console.log("loggato")
          console.log(response.data)
          if (response.data.logged){
            localStorage.setItem("tok",response.data.tok);
            this.$store.state.logged = response.data.logged;
            this.$store.state.userData = response.data.userData;
            putLocalSingleData("userData",response.data.userData,"userData","userData")
            setTimeout(() => {
              if (this.$route.path==='/') this.gotoToPage("/userHP")
            }, 300);
          } else {
            this.gotoToPage("/")
          }
          console.log("ciao")
          //console.log(response.data)
      });       
    }
    },
      gotoToPage(page){
        if (page!==this.$route.path) this.$router.push(page);
      },
      homeP(){
        this.gotoToPage("/userHP")
      },
      testOffLine(){
        //
      },
      checkConActions(timeToCheck){
         setInterval(() => {
        this.checkConnection()
        setTimeout(() => {
          console.log("online")
          console.log(this.$store.state.OnLine)
        }, 1000); 
      }, timeToCheck*1000);
      },
      async checkConnection(){

        await this.axios({
          method: 'get',
          url: 'auth/test?t='+Date.now(),

          }).then((response) => {
            console.log(response.data)
              if (response.data.connection){
              serverBus.$emit("setState",true);} else{
               serverBus.$emit("setState",false); 
              }
              
          }).catch(function (error) {
              console.log(error)
              serverBus.$emit("setState",false);
            });  
  
    
      },

    },
    watch: {

    },    
}
async function putLocalSingleData(dataId,data,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    set(dataId,data,customStore)
}
async function getLocalSingleData(objId,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    get(objId,customStore).then((val) => {
        console.log(val);
        return val
})}
async function updateSingleData(objId,newData,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    update(objId, newData,customStore);
}
async function getAllEntries(dbName,storeName){
    const customStore = createStore(dbName, storeName);
    entries(customStore).then((entries) => console.log(entries));
}
async function delSingleData(objId,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    del(objId,customStore);
}
</script>