<template>
<v-container>
    <v-container class="d-flex justify-center mt-0">
      <v-card
      elevation="0">
      <img 
        alt="TreeTraker logo" 
        :width="widthGrafica/2"
        contain
        src="../assets/logo-green.png">
            </v-card>
    </v-container>
    <v-container class="d-flex justify-center mt-0">
    <v-card
      elevation="0">
      <img 
        alt="TreeTraker logo2" 
        :width="widthGrafica"
        class="align-self-stretch"
        contain        
        src="../assets/logo-image.png"

        /> 
    </v-card>
    </v-container>        
    <v-container class="d-flex justify-center mt-0">  
      <p class="text-center text-h6 ">
        <span class="greenColor"> Care for your tree, track our impact</span>
      </p>   
    </v-container>      
    <v-container class="d-flex justify-center mt-0">  
      <v-btn
        small
        elevation="3"
        color="#215356"
        class="white--text"
        @click="login"
      >
      Log In
    </v-btn>
    </v-container>  
    <v-container class="d-flex justify-center mt-0">  
      <p class="text-center text-subtitle-2 ">
        <span class="greenColor" @click="reqAccess"> Request Access </span>
      </p>   
    </v-container>  

</v-container>
</template>
<style scoped>
.greenColor{
  color:#215356
}

</style>
<script>
import AddNewDataTree from './addNewDataTree.vue'
export default {
    name: 'TreeHome',
    props:[''],
    components: {
        AddNewDataTree
      
    },
    computed:{ 
      widthGrafica () {
        var risp = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': 
          case 'sm': 
          case 'md': 
            risp = 200          
          break
          case 'lg': 
          case 'xl': 
            risp = 300
          break
        }
        return risp
      },
    },
    beforeCreate(){
      //prima della creazione
    },
    created(){
      //dopo la creazione
    }, 
    beforeMount(){
      //prima del mount
    },    
    mounted(){
      //dopo il mount
      this.altezzaMax = this.$vuetify.breakpoint.height-130
    },
    beforeUpdate(){
      //prima dell'aggiornamento
    }, 
    updated(){
      //dopo l'aggiornamento
    },
    beforeDestroy(){
      //prima della distruzione
    },
    destroyed(){  
      //dopo la distruzione
    },   
    data:()=> ({
        altezzaMax:null,
    }),
    methods: {
      reqAccess(){
        this.gotoToPage("/reqAcc")
      },
      gotoToPage(page){
        if (page!==this.$route.path) this.$router.push(page);
      },
      myTrees(){
        this.gotoToPage("/userHP")
      },
      login(){
        //this.$store.state.loggato = true
        this.gotoToPage("/userLogin")
      },
      logout(){
        this.$store.state.loggato = false
        this.gotoToPage("/")
      }
    },
    watch: {

    },    
}
</script>