<template>
  <div>
    <div v-show="homePage" class="mt-2">
      <v-container 
        class="d-flex justify-center mt-0" 
      >
        <v-card
          v-show="(this.localTrees.length===0)"
          elevation="0"
          height="200">
          <v-img
          src="../assets/NoTreesUser.png"
          height="160px"
          width="160px"
          contain
          />
          <div class="subtitle-2 bold greenColor mt-2" >Add details of your tree plantation</div>
        </v-card>
      </v-container>   
      <v-container class="mt-0">
        <v-card
          elevation="0"
          height="300px"
          v-show="(this.localTrees.length>0)"
        >
          <span class="subtitle-2 bold greenColor">My Trees</span>
          <v-slide-group
            v-model="sliderMyTrees"
          >
            <v-slide-item
              v-for="tree in localTrees"
              :key="tree.id+makeid(40)"
            >
              <card-my-tree-preview
                v-bind:tree="tree"
                v-bind:type="normale"
                class="mb-3 mt-3"
              />
            </v-slide-item>
          </v-slide-group>
        </v-card>
        <v-card
          elevation="0"
          height="300px"
          class="mt-6"
          v-show="(this.localTreesDraft.length>0)"
        >
          <span class="subtitle-2 bold greenColor">My Drafts</span>
          <v-slide-group
          v-model="sliderMyTrees"
          class="pa-0"
          active-class="success"
          >
            <v-slide-item
                v-for="tree in localTreesDraft"
                :key="tree.id+makeid(40)"
            >
              <card-my-tree-preview
              v-bind:tree="tree"
              v-bind:type="draft"
              class="mb-3 mt-3"
              />
            </v-slide-item>
          </v-slide-group>
        </v-card>
        <v-card
        elevation="0"
        height="300px"
          class="mt-6 mb-12">
          <span class="subtitle-2 bold greenColor">Public Trees</span>
          <v-slide-group
          v-model="sliderMyTrees"
          class="pa-0"
          active-class="success"
          >
            <v-slide-item
              v-for="tree in localPublicTrees"
              :key="tree.id+makeid(40)"
            >
              <card-my-tree-preview
              v-bind:tree="tree"
              v-bind:type="draft"
              class="mb-3 mt-3"
              />
            </v-slide-item>
          </v-slide-group>
        </v-card>
        <v-fab-transition>
          <v-btn
            fab
            dark
            small
            fixed
            top
            right
            color="#215356"
            @click="addNewTree"
            class="mt-12"
          >
            <v-icon>mdi-plus</v-icon> 
          </v-btn>
        </v-fab-transition>
      </v-container>
    </div>
    <v-container class="mt-0" v-show="!homePage">
      <v-row>
        <v-icon @click="homePage=true">mdi-close</v-icon>
        <v-btn
          x-small
          color="#FFF"
          @click="addMeasurement" 
          v-show="((detailsData.fkApprovalStatus!=='2') && (detailsData.fkApprovalStatus!=='5'))"
          class="mt-3 ml-3"
        >
          <v-icon>mdi-plus</v-icon>Add Data 
        </v-btn> 
        <v-btn
          x-small
          color="#FFF"
          @click="downloadTreeLocally" 
          v-show="((detailsData.fkApprovalStatus!=='2') && (!detailsData.localTree))"
          class="mt-3 ml-3"
        >
          <v-icon>mdi-arrow-down-thick</v-icon>Download Locally 
        </v-btn>       
      </v-row>
      <tree-details
        v-bind:treeData="detailsData"
      />
      <add-new-data-tree
        v-show="(detailsData.fkApprovalStatus==='5')"
        v-bind:treeData="detailsData"              
      />      
      <v-dialog
        v-model="dialogAddNewDataToTree"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-icon @click="dialogAddNewDataToTree=false">mdi-close</v-icon>
          <v-card-text>
            <add-new-data-tree
              v-bind:treeData="detailsData"              
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>  
    <v-snackbar
      v-model="snackbarGeneric"
      timeout="2000"
      centered
    >
      {{ snackbarGenericMessage }}
      <v-btn
        color="blue"
        text
        @click="snackbarGeneric = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<!--
      <v-btn
        x-small
        color="#FFF"
        @click="test" 
        class="mt-3 ml-3"
      >
        <v-icon>mdi-plus</v-icon>TEST 
      </v-btn> 
-->

  <!--<v-btn @click="uploadLocalTrees">upload local trees</v-btn><v-btn @click="testEmailFake">fEmail</v-btn> -->
  <!--  <v-container class="d-flex justify-center mt-3 mb-12" >
        <v-btn
            color="#215356"
            dark
            x-small
            @click="addNewTree"
        >
        <v-icon>mdi-plus</v-icon> ADD NEW TREE
      </v-btn> 
        <v-btn
            color="#215356"
            dark
            x-small
            class="ml-3"
            @click="testGET"
            >
            <v-icon>mdi-palm-tree</v-icon> BROWSE PUBLIC TREES
        </v-btn> 
    </v-container>      -->

<style scoped>
.scrollo {
  overflow: scroll;
    overflow-y: scroll;
}
.greenColor{
  color:#215356
}
</style>
<script>
import cardMyTreePreview from '../components/cardMyTreesPreview.vue'
import treeDetails from '../components/treeDetails.vue'
import addNewDataTree from './addNewDataTree.vue'
import { serverBus } from '../main';
import toDataURL from '../common/commonFunctions'
//import createLocalDb from '../common/localDbFunctions'
import { set, get,createStore,entries, del,update} from 'idb-keyval';




export default {
    name: 'userHomePage',
    props:[''],
    components: {
      cardMyTreePreview,treeDetails,addNewDataTree
    },
    beforeCreate(){
      serverBus.$on("moveOnPage1",() =>{
        this.dialogAddNewDataToTree=false
        this.homePage=true
      }); 
      serverBus.$on("snackbarGeneric",(message) =>{
        this.snackbarGenericMessage=message
        this.snackbarGeneric=true
      });             
      serverBus.$on("viewRemoteTree",(data) =>{
        this.homePage = false
        this.detailsData = data
        console.log("veiwremotetree")
        console.log(data)
        serverBus.$emit("viewSingleTree");
        //serverBus.$off("viewRemoteTree")
      });       
      serverBus.$on("updateLocalTree",(data) =>{
        console.log(data)
        this.localTrees.push(data)

      }); 
      serverBus.$on("updatePublicTrees",(data) =>{
        console.log(data)
        this.localPublicTrees.push(data)

      });      
      serverBus.$on("updateLocalTreeDraft",(data) =>{
        console.log(data)
        this.localTreesDraft.push(data)

      }); /*
      serverBus.$on("dialogModTree",(stat) =>{

      }); */
    },
    mounted(){
      this.setView()
      console.log("eeeeee")
      getAllEntries("RemoteToLocal","RemoteToLocal")
      setTimeout(() => {
        //delSingleData("714","RemoteToLocal","RemoteToLocal")
        this.viewListOfLocalDownloadedTrees()
      }, 3000);

    },    
    data:()=> ({
      alignments: [
        'start',
        'center',
        'end',
      ],  
      sliderMyTrees: null,
      sliderMyDraft: null,
      noOne:true,


      //alberi salvati locali
      localTrees:[],
      localTreesDraft:[],
      localPublicTrees:[],

      //download alberi remoti
      downloadedLocalTrees:[],

      normale:"normale",
      draft:"draft",

      //visuDetails
      homePage:true,
      detailsData:[],
      tempRemoteUpload:null,

      //upload alberi
      tempOffLine:[],

      //add data to trees
      dialogAddNewDataToTree:false,
      snackbarGeneric:false,
      snackbarGenericMessage:"",
    }),  
    methods: {
    testEmailFake(){
            var locSto = localStorage.getItem("tok");
            console.log("loc:"+locSto)
            this.axios({
            method: 'post',
            url: 'email/testemail',
            data: {
              tok:locSto,
              name:"Giansu",
              email:"gianluca.scandamarro@erm.com",
              subject:"Soggetto",
              body:"Corpo messaggione"
            }
            }).then((response) => {
              console.log(response.data)
            });  

    },


    createLocalDbLocalTreeTypeList(dbName){
    let request = window.indexedDB.open(dbName, 1);
    request.onerror = function() {
        alert('Database failed to open');
    };
    // onsuccess handler signifies that the database opened successfully
    request.onsuccess = function() {
        console.log('Database opened successfully');
    };     
    request.onupgradeneeded = function(e) {
        let db = e.target.result;
        let objectStore = db.createObjectStore(dbName, { keyPath: 'id', autoIncrement:true });
        objectStore.createIndex('idEx', 'idEx', { unique: false });
        console.log('Database trees setup complete');
    };        
    }, 
      test(){
        console.log(this.localTrees)
      },
        makeid(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
        charactersLength));
            }
            return result;
        },      
      saveRemoteLocally(singleTree){
        console.log("salva remoto in locale")
        let data = { remoteId: singleTree.id,treeName: singleTree.recordName, treeOwner: singleTree.userFirstName, selectedTreeType: singleTree.fkTreeTypeList, commonName_EN: singleTree.commonName_EN, treeHeight:singleTree.height_m, treeWidth: singleTree.diameter_cm, plantationDate: singleTree.operationDate,photoBlob: singleTree.imageFilePath_imgL,fkApprovalStatus:"5",syncId:this.makeid(200) };  
        console.log("singletree")
        console.log(singleTree)    
        console.log(data)    

        putLocalSingleData(data.remoteId,data,"RemoteToLocal","RemoteToLocal")

        //this.saveDataToLocalDb(data,"treeCacheAddData")
        setTimeout(() => {
          this.dialogAddNewDataToTree=false
          this.homePage=true
        }, 500);           
      },  




      saveDataToLocalDb(data,dbName){
        let request = window.indexedDB.open(dbName, 1);
        request.onerror = function() {
          console.log('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
          console.log('Database opened successfully');

          // Store the opened database object in the db variable. This is used a lot below
          var db = request.result;

          // open a read/write db transaction, ready for adding the data
          let transaction = db.transaction([dbName], 'readwrite');
          // call an object store that's already been added to the database
          let objectStore = transaction.objectStore(dbName);         
          // Make a request to add our newItem object to the object store
          let request2 = objectStore.add(data);
          request2.onsuccess = function() {
            // Clear the form, ready for adding the next entry

          };

          // Report on the success of the transaction completing, when everything is done
          transaction.oncomplete = function() {
            console.log('Transaction completed: database modification finished.');

            // update the display of data to show the newly added item, by running displayData() again.
            //displayData();
          };

          transaction.onerror = function() {
            console.log('Transaction not opened due to error');
          };   
        };  
      },         
      downloadTreeLocally(){
        var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
        method: 'post',
        url: 'trees/getTreeInfo',
        data: {
          tok:locSto,
          treeId: this.detailsData.id,
        }
        }).then((response) => {
          this.saveRemoteLocally(response.data)
        });  
      },
      addMeasurement(){ //
        this.dialogAddNewDataToTree = true
        console.log(this.detailsData)
      },
      getAllLocalTrees(){
        const customStore = createStore("treeSavedLocally", "treeSavedLocally");
        entries(customStore).then((entries) => {
          console.log("getAllLocalTrees")
          console.log(entries)
          entries.forEach(oneElement => {
            var element = oneElement[1]
            var photoHtml = (element.imageFilePath_imgL!==null) ? window.URL.createObjectURL(element.imageFilePath_imgL) : null
            var data = {  treeName:element.treeName,
              photoBlob:photoHtml,
              realBlob:element.imageFilePath_imgL,
              localTree:true,
              owner:element.owner,
              syncId:element.syncId,
              plantationDate:element.operationDateTime,
              selectedTreeType:element.fkTreeTypeList,
              treeHeight:element.height_m,
              treeWidth:element.diameter_cm,
              id:element.id,
              location:element.location,
              locationSelection:element.locationSelection,
              tree_nr:element.tree_nr,

              }
              this.localTrees.push(data)
          })
          });
      },
      getAllLocalDraftTrees(){
        const customStore = createStore("treeDraftLocally", "treeDraftLocally");
        entries(customStore).then((entries) => {
          console.log("getAllLocalTrees")
          console.log(entries)
          entries.forEach(oneElement => {
            var element = oneElement[1]
            var photoHtml = (element.photoBlob!==null) ? window.URL.createObjectURL(element.photoBlob) : null
            var data = {  treeName:element.treeName,
              photoBlob:photoHtml,
              realBlob:element.photoBlob,
              localTree:true,
              owner:element.treeOwner,
              syncId:element.syncId,
              plantationDate:element.plantationDate,
              selectedTreeType:element.selectedTreeType,
              treeHeight:element.treeHeight,
              treeWidth:element.treeWidth,
              id:element.id
              }
              this.localTreesDraft.push(data)
          })
          });
      },      
      setView(){
        this.localTrees = []
        this.localPublicTrees = []
        this.localTreesDraft = []
      //dopo il mount
      
      //createLocalDb('treeCache')
    
      setTimeout(() => {
        //this.updateMyTreeUpdateView('treeCache',"updateLocalTree")
        this.getAllLocalTrees()
        this.getAllLocalDraftTrees()
      }, 500);
/*        createLocalDb('treeCacheDraft')
      setTimeout(() => {
        this.updateMyTreeUpdateView('treeCacheDraft',"updateLocalTreeDraft")
      }, 500); 
*/     
/*
      this.createLocalDbLocalFromRemote('treeCacheAddData')
      setTimeout(() => {
        this.updateMyTreeUpdateViewFromRemote('treeCacheAddData',"updateLocalTree")
      }, 500);

      this.createLocalDbLocalAddedDataFromRemote('treeCacheLocalAddedData')
      setTimeout(() => {
        //this.updateMyTreeUpdateViewFromRemote('treeCacheLocalAddedData',"updateLocalTree")
      }, 500);
*/
      if (this.$store.state.OnLine){    
        setTimeout(() => {
            this.getRemoteTrees()
            this.getPublicTrees()
            this.getRemoteTreeTypeList()
        }, 1000);}

        setTimeout(() => {
            console.log("utente:"+this.$store.state.userData.firstName)
            console.log("drafff")
            console.log(this.localTreesDraft)
        }, 3000);
      },
      getRemoteTreeTypeList(){
        /*
        var request = indexedDB.deleteDatabase("localTreeTypes");
        setTimeout(() => {
          this.createLocalDbLocalTreeTypeList("localTreeTypes")
          this.axios({
            method: 'get',
            url: 'trees/treeTypeList'
            }).then((response) => {
              console.log("localtree")
              console.log(response.data)
              response.data.forEach((element) => {  
                let data = { idEx: element.id,
                            text: element.text
                          }
                console.log("data")
                console.log(data)
                this.saveDataToLocalDb(data,"localTreeTypes")
              })
              //saveDataToLocalDb(response.data,"localTreeTypes")
            });             
          }, 400);
          */
          //this.createLocalDbLocalTreeTypeList("localTreeTypes")
          this.axios({
            method: 'get',
            url: 'trees/treeTypeList'
            }).then((response) => {
              console.log("localtree")
              console.log(response.data)
              response.data.forEach((element) => {  
                /*let data = { idEx: element.id,
                            text: element.text
                          }*/
                console.log("data")
                console.log(element)
                putLocalSingleData(element.id,element,"localTreeTypesList","localTreeTypesList")
                //this.saveDataToLocalDb(data,"localTreeTypes")
              })
              //saveDataToLocalDb(response.data,"localTreeTypes")
            });  
          this.axios({
            method: 'get',
            url: 'trees/treeTypeListDetails'
            }).then((response) => {
              console.log("localtree")
              console.log(response.data)
              response.data.forEach((element) => {  
                /*let data = { idEx: element.id,
                            text: element.text
                          }*/
                console.log("data")
                console.log(element)
                putLocalSingleData(element.id,element,"localTreeTypesListDetails","localTreeTypesListDetails")
                //this.saveDataToLocalDb(data,"localTreeTypes")
              })
              //saveDataToLocalDb(response.data,"localTreeTypes")
            });  



      },
      deleteDataFromLocalDb(objId,dbName){
        let request = window.indexedDB.open(dbName, 1);

        request.onerror = function() {
          console.log('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
          console.log('Database opened successfully');

          // Store the opened database object in the db variable. This is used a lot below

          console.log("redbbb")
          console.log(request.result)

          var db = request.result;     

          var request2 = db.transaction([dbName], "readwrite")
                          .objectStore(dbName)
                          .delete(objId);
          request2.onsuccess = function(event) {
            // It's gone!
          };
        }
        
      },       
      saveOnLineWithBlob(dataBlob){
          // userId treeName treeOwner treeType treeHeight treeWidth plantationDate photoBlob syncId flagDraft

/*campi locali a sinistra
      let data = { treeName: this.treeName,
                  owner: this.treeOwner, 
                  fkTreeTypeList: this.selectedTreeType, 
                  fkUser:this.$store.state.userData.id,
                  operationDateTime: this.plantationDate,
                  fkApprovalStatus:"2",
                  tree_nr:(this.numberOfTrees) ? this.numberOfTrees :"1",
                  height_m:this.treeHeight, 
                  diameter_cm: this.treeWidth, 
                  imageFilePath_imgL:this.photoBlob, 
                  location:JSON.stringify(this.coordCenterPreview),
                  locationSelection:JSON.stringify(this.coordPolyPreview),syncId:this.makeid(200)
                };
  campi remoti a sinistra
            let data = { treeName: this.treeName,
                      owner: this.treeOwner, 
                      fkTreeTypeList: this.selectedTreeType, 
                      fkUser:this.$store.state.userData.id,
                      operationDateTime: this.plantationDate,
                      fkOperationType:"1",
                      fkTypeOfSubmission:"1",                      
                      fkApprovalStatus:"2",
                      tree_nr:(this.numberOfTrees) ? this.numberOfTrees :"1",
                      height_m:this.treeHeight, 
                      diameter_cm: this.treeWidth, 
                      imageFilePath_imgL:dataBlob, 
                      location:JSON.stringify(this.coordCenterPreview),
                      locationSelection:JSON.stringify(this.coordPolyPreview),
                    };
  
  
  */
 
 


          let data = { treeName: this.tempOffLine.treeName,
                      owner: this.tempOffLine.owner, 
                      fkTreeTypeList: this.tempOffLine.fkTreeTypeList, 
                      fkUser:this.$store.state.userData.id,
                      fkOperationType:"1",
                      fkTypeOfSubmission:"1",
                      operationDateTime: this.tempOffLine.operationDateTime,
                      fkApprovalStatus:"2",
                      tree_nr: this.tempOffLine.tree_nr,
                      height_m:this.tempOffLine.height_m, 
                      diameter_cm: this.tempOffLine.diameter_cm, 
                      imageFilePath_imgL:dataBlob,
                      localSyncCode:this.tempOffLine.syncId,
                      location:JSON.stringify(this.coordCenterPreview),
                      locationSelection:JSON.stringify(this.coordPolyPreview),
                    };
          console.log("saveOnLineWithBlob")
          console.log(data)
          var locSto = localStorage.getItem("tok");
          console.log("loc:"+locSto)
          this.axios({
          method: 'post',
          url: 'trees/saveLocalTreeOnLine',
          data: {
            tok:locSto,
            tree: data,
          }
          }).then((response) => {
            console.log("scaricati da remoto")
            console.log(response.data)
          });  
      },
      saveOnLineFromLocal(){
          // userId treeName treeOwner treeType treeHeight treeWidth plantationDate photoBlob syncId flagDraft
          let data = { 
            treeName: this.tempOffLine.treeName,
            owner: (this.tempOffLine.owner) ? this.tempOffLine.owner : this.tempOffLine.treeOwner, 
            fkTreeTypeList: this.tempOffLine.selectedTreeType, 
            fkUser:this.$store.state.userData.id,
            fkOperationType:"1",
            fkTypeOfSubmission:"1",
            operationDateTime: this.tempOffLine.plantationDate,
            fkApprovalStatus:"2",
            tree_nr:"1",
            height_m:this.tempOffLine.treeHeight, 
            diameter_cm: this.tempOffLine.treeWidth, 
            imageFilePath_imgL:this.tempOffLine.photoBlob,
            localSyncCode:this.tempOffLine.syncId
          };
          console.log("saveOnLineFromLocal")
          console.log(data)
          var locSto = localStorage.getItem("tok");
          console.log("loc:"+locSto)
          this.axios({
          method: 'post',
          url: 'trees/saveLocalTreeOnLine',
          data: {
            tok:locSto,
            tree: data,
          }
          }).then((response) => {
            console.log("scaricati da remoto")
            console.log(response.data)
          });  
      },      
      /*
      createLocalTreeListWithData(dbName){
        var ritornoData = []
        let request = window.indexedDB.open(dbName, 1);
        request.onerror = function() {
            console.log('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
          
            console.log('Database opened successfully');

            // Store the opened database object in the db variable. This is used a lot below
            var db = request.result;

            console.log("result")
            console.log(db)

            // open a read/write db transaction, ready for adding the data
            let transaction = db.transaction([dbName]);
            // call an object store that's already been added to the database
            let objectStore = transaction.objectStore(dbName);  
            
            objectStore.openCursor().onsuccess = function(event) {
            var cursor = event.target.result;
            var indice = 0
            if (cursor) {
              var photoHtml = (cursor.value.photoBlob!==null) ? window.URL.createObjectURL(cursor.value.photoBlob) : null
              var data = 
                {  treeName:cursor.value.treeName,
                  photoBlob:photoHtml,
                  realBlob:cursor.value.photoBlob,
                  localTree:true,
                  owner:cursor.value.treeOwner,
                  syncId:cursor.value.syncId,
                  plantationDate:cursor.value.plantationDate,
                  selectedTreeType:cursor.value.selectedTreeType,
                  treeHeight:cursor.value.treeHeight,
                  treeWidth:cursor.value.treeWidth,
                  id:cursor.value.id
                }
              ritornoData.push(data)
              console.log(data)
              //ritornoData[] = data
              cursor.continue();
            }
            else {
            }
            };

            let request2 = objectStore.get("");

            request2.onsuccess = function(event) {

            console.log('Richiesta creazione OK.');
            console.log(event)
            };

            transaction.oncomplete = function() {
            };

            transaction.onerror = function() {
            console.log('Recupero fallito');
            };   
        };      
        console.log("finito alberi lista locali");
        console.log(ritornoData)
        this.tempRemoteUpload = ritornoData
        return ritornoData
      },
      */
      async uploadLocalTrees(){
        const customStore = createStore("treeSavedLocally", "treeSavedLocally");
        entries(customStore).then((entries) => {
          console.log("getAllLocalTrees")
          console.log(entries)
          entries.forEach(oneElement => {
            var element = oneElement[1]
            element.fkUser = this.$store.state.userData.id
            this.tempOffLine = element
            var photoHtml = window.URL.createObjectURL(element.imageFilePath_imgL)
            toDataURL(photoHtml, this.saveOnLineWithBlob)
            console.log("SingoloAlbero")
            console.log(element)
            delSingleData(element.syncId,"treeSavedLocally","treeSavedLocally")

            //localTrees.push(element)
          })
          });

        setTimeout(() => {
          this.setView()
        }, 2000);          
          /*
        console.log("alberelliLocaliiiiiiUppati")
        console.log(localTrees)

        serverBus.$emit("waitingOnline",10);

        setTimeout(() => {
        var locSto = localStorage.getItem("tok");
         this.axios({
        method: 'post',
        url: 'trees/uploadLocalTrees',
        data: {
          tok:locSto,
          trees: localTrees,
        }
        }).then((response) => {     

            console.log("pubblicato alberi online")
            console.log(response.data)
            serverBus.$emit("waitingOnline",0);
            response.data.notPresent.forEach((element) => {  
              this.tempOffLine = element
              setTimeout(() => {
                console.log("nonpresente:"+element.syncId)
                console.log(element)
                //var photoHtml = window.URL.createObjectURL(element.photoBlob)  

                var tempLocal = getLocalSingleData(element.syncId,"treeSavedLocally","treeSavedLocally")

                console.log("tempLocalNonPresente:"+element.syncId)
                console.log(tempLocal)

                //toDataURL(tempLocal.photoBlob, this.saveOnLineWithBlob)

                this.saveOnLineFromLocal()
                       
                setTimeout(() => {
                  //this.deleteDataFromLocalDb(element.id,'treeCache')
                  delSingleData(element.syncId,"treeSavedLocally","treeSavedLocally")
                }, 100);         
              }, 200);

            })
            response.data.present.forEach((element) => {  
              this.tempOffLine = element
              setTimeout(() => {
                console.log("presente:"+element.syncId)
                console.log(element)
                //var photoHtml = window.URL.createObjectURL(element.photoBlob)  
                //var tempLocal = getLocalSingleData(element.syncId,"treeSavedLocally","treeSavedLocally")
                //toDataURL(tempLocal.photoBlob, this.saveOnLineWithBlob) 
                var tempLocal = getLocalSingleData(element.syncId,"treeSavedLocally","treeSavedLocally")

                console.log("tempLocalPresente:"+element.syncId)
                console.log(tempLocal)
                this.saveOnLineFromLocal()
                      
                setTimeout(() => {
                  //this.deleteDataFromLocalDb(element.id,'treeCache')
                  delSingleData(element.syncId,"treeSavedLocally","treeSavedLocally")
                }, 100);         
              }, 200);

            })            
            setTimeout(() => {
              this.setView()
            }, 2000);
        });            
        }, 500);
 
       */
      },
      getPublicTrees(){
        var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
        method: 'post',
        url: 'trees/PublicTrees',
        data: {
          tok:locSto,
          //tree: data,
        }
        }).then((response) => {     

          console.log("alberi remoti pubblici")
          console.log(response.data)
          response.data.forEach(element => {
          console.log(element)
          var data = {treeName:element.recordName,
                      fkApprovalStatus:element.fkApprovalStatus,
                      photoBlob:element.imageFilePath_imgL,
                      realBlob:null,
                      localTree:false,
                      owner:element.owner,
                      syncId:null,
                      plantationDate:element.operationDateTime,
                      selectedTreeType:element.fkTreeTypeList,
                      treeHeight:element.height_m,
                      treeWidth:element.diameter_cm,
                      id:element.id,
                      publicTree:true,
                      }
          serverBus.$emit("updatePublicTrees",data);
        });
        });             
    },        
      getRemoteTrees(){
        var locSto = localStorage.getItem("tok");
        console.log("loc:"+locSto)
        this.axios({
        method: 'post',
        url: 'trees/treeList',
        data: {
          tok:locSto,
          //tree: data,
        }
        }).then((response) => {     
          console.log("alberi remoti")
          console.log(response.data)
          response.data.forEach(element => {
          console.log(element)
          const customStore = createStore("RemoteToLocal", "RemoteToLocal");
          get(element.id,customStore).then((val) => {
              console.log("raccolto Locale:")
              console.log(val);
              console.log("raccolto Remooto:")
              console.log(element)
              if ((val!==undefined)&&(element!==undefined)){
                if (val.remoteId!==element.id){
                    var data = {
                      treeName:element.recordName,
                      fkApprovalStatus:element.fkApprovalStatus,
                      photoBlob:element.imageFilePath_imgL,
                      realBlob:null,
                      localTree:false,
                      owner:element.owner,
                      syncId:null,
                      plantationDate:element.operationDateTime,
                      selectedTreeType:element.fkTreeTypeList,
                      treeHeight:element.height_m,
                      treeWidth:element.diameter_cm,
                      id:element.id,
                      }
                    serverBus.$emit("updateLocalTree",data);                
                }
              } else {
                    var data = {
                      treeName:element.recordName,
                      fkApprovalStatus:element.fkApprovalStatus,
                      photoBlob:element.imageFilePath_imgL,
                      realBlob:null,
                      localTree:false,
                      owner:element.owner,
                      syncId:null,
                      plantationDate:element.operationDateTime,
                      selectedTreeType:element.fkTreeTypeList,
                      treeHeight:element.height_m,
                      treeWidth:element.diameter_cm,
                      id:element.id
                      }
                    serverBus.$emit("updateLocalTree",data);  
              }
        })
/*
          var locale = getLocalSingleData(parseInt(element.id),"RemoteToLocal","RemoteToLocal")

          console.log("LOCALLEEEEE:"+element.id)
          console.log(locale)


          var data = {
            treeName:element.recordName,
            fkApprovalStatus:element.fkApprovalStatus,
            photoBlob:element.imageFilePath_imgL,
            realBlob:null,
            localTree:false,
            owner:element.owner,
            syncId:null,
            plantationDate:element.operationDateTime,
            selectedTreeType:element.fkTreeTypeList,
            treeHeight:element.height_m,
            treeWidth:element.diameter_cm,
            id:element.id
            }
          serverBus.$emit("updateLocalTree",data);
*/

        });
        });             
    },

    createLocalDbLocalAddedDataFromRemote(dbName){
    let request = window.indexedDB.open(dbName, 1);
    request.onerror = function() {
      alert('Database failed to open');
    };
    // onsuccess handler signifies that the database opened successfully
    request.onsuccess = function() {
        console.log('Database opened successfully');

        // Store the opened database object in the db variable. This is used a lot below
        //var db = request.result;

        
        // Run the displayData() function to display the notes already in the IDB
        //displayData();
    };     
    
    // Setup the database tables if this has not already been done
    request.onupgradeneeded = function(e) {
        // Grab a reference to the opened database
        let db = e.target.result;

        // Create an objectStore to store our notes in (basically like a single table)
        // including a auto-incrementing key
        let objectStore = db.createObjectStore(dbName, { keyPath: 'id', autoIncrement:true });

        // Define what data items the objectStore will contain
        objectStore.createIndex('originalId', 'originalId', { unique: false });

        console.log('Database setup complete');
    };        
    },       
    createLocalDbLocalFromRemote(dbName){
    let request = window.indexedDB.open(dbName, 1);
    request.onerror = function() {
        alert('Database failed to open');
    };
    // onsuccess handler signifies that the database opened successfully
    request.onsuccess = function() {
        console.log('Database opened successfully');

        // Store the opened database object in the db variable. This is used a lot below
        //var db = request.result;

        
        // Run the displayData() function to display the notes already in the IDB
        //displayData();
    };     
    
    // Setup the database tables if this has not already been done
    request.onupgradeneeded = function(e) {
        // Grab a reference to the opened database
        let db = e.target.result;

        // Create an objectStore to store our notes in (basically like a single table)
        // including a auto-incrementing key
        let objectStore = db.createObjectStore(dbName, { keyPath: 'id', autoIncrement:true });

        // Define what data items the objectStore will contain
        objectStore.createIndex('remoteId', 'remoteId', { unique: false });

        console.log('Database setup complete');
    };        
    },  
    async viewListOfLocalDownloadedTrees(){
      const customStore = createStore("RemoteToLocal", "RemoteToLocal");
      entries(customStore).then((entries) => {
        entries.forEach(element =>{
          var intro = element[1]
          var photoHtml = (intro.photoBlob!==null) ? intro.photoBlob : null
          var data = {  treeName:intro.treeName,
                      commonName_EN:intro.commonName_EN,
                      photoBlob:photoHtml,
                      realBlob:intro.photoBlob,
                      localTree:true,
                      fkApprovalStatus:intro.fkApprovalStatus,
                      owner:intro.treeOwner,
                      syncId:intro.syncId,
                      plantationDate:intro.plantationDate,
                      selectedTreeType:intro.selectedTreeType,
                      treeHeight:intro.treeHeight,
                      treeWidth:intro.treeWidth,
                      id:intro.id,
                      remoteId:intro.remoteId
                      }
          this.localTrees.push(data)
          console.log("eeeeeentrieeeesss")
          console.log(data)          
        })
      });
    },
    updateMyTreeUpdateViewFromRemote(dbName,emitRelativo){
        let request = window.indexedDB.open(dbName, 1);
        request.onerror = function() {
            console.log('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
            console.log('Database opened successfully');

            // Store the opened database object in the db variable. This is used a lot below
            var db = request.result;

            console.log("result")
            console.log(db)

            // open a read/write db transaction, ready for adding the data
            let transaction = db.transaction([dbName]);
            // call an object store that's already been added to the database
            let objectStore = transaction.objectStore(dbName);  
            
            objectStore.openCursor().onsuccess = function(event) {
            var cursor = event.target.result;
            if (cursor) {
                var photoHtml = (cursor.value.photoBlob!==null) ? cursor.value.photoBlob : null
                var data = {  treeName:cursor.value.treeName,
                            commonName_EN:cursor.value.commonName_EN,
                            photoBlob:photoHtml,
                            realBlob:cursor.value.photoBlob,
                            localTree:true,
                            fkApprovalStatus:cursor.value.fkApprovalStatus,
                            owner:cursor.value.treeOwner,
                            syncId:cursor.value.syncId,
                            plantationDate:cursor.value.plantationDate,
                            selectedTreeType:cursor.value.selectedTreeType,
                            treeHeight:cursor.value.treeHeight,
                            treeWidth:cursor.value.treeWidth,
                            id:cursor.value.id,
                            remoteId:cursor.value.remoteId
                            }
                console.log("cursor "+dbName)
                console.log(cursor)
                serverBus.$emit(emitRelativo,data);
                cursor.continue();
            }
            else {
                console.log("finito alberi locali "+dbName);
            }
            };
            
            // Make a request to add our newItem object to the object store
            let request2 = objectStore.get("");
            request2.onsuccess = function(event) {
            // Clear the form, ready for adding the next entry
            console.log('Richiesta recupero OK.');
            console.log(event)
            };

            // Report on the success of the transaction completing, when everything is done
            transaction.oncomplete = function() {

            // update the display of data to show the newly added item, by running displayData() again.
            //displayData();
            };

            transaction.onerror = function() {
            console.log('Recupero fallito');
            };   
        };  
    },     
    
    
    updateMyTreeUpdateView(dbName,emitRelativo){
        let request = window.indexedDB.open(dbName, 1);
        request.onerror = function() {
            console.log('Database failed to open');
        };
        // onsuccess handler signifies that the database opened successfully
        request.onsuccess = function() {
            console.log('Database opened successfully');

            // Store the opened database object in the db variable. This is used a lot below
            var db = request.result;

            console.log("result")
            console.log(db)

            // open a read/write db transaction, ready for adding the data
            let transaction = db.transaction([dbName]);
            // call an object store that's already been added to the database
            let objectStore = transaction.objectStore(dbName);  
            
            objectStore.openCursor().onsuccess = function(event) {
            var cursor = event.target.result;
            if (cursor) {
                var photoHtml = (cursor.value.photoBlob!==null) ? window.URL.createObjectURL(cursor.value.photoBlob) : null
                var data = {  treeName:cursor.value.treeName,
                            photoBlob:photoHtml,
                            realBlob:cursor.value.photoBlob,
                            localTree:true,
                            owner:cursor.value.treeOwner,
                            syncId:cursor.value.syncId,
                            plantationDate:cursor.value.plantationDate,
                            selectedTreeType:cursor.value.selectedTreeType,
                            treeHeight:cursor.value.treeHeight,
                            treeWidth:cursor.value.treeWidth,
                            id:cursor.value.id
                            }
                console.log("cursor "+dbName)
                console.log(cursor)
                serverBus.$emit(emitRelativo,data);
                cursor.continue();
            }
            else {
                console.log("finito alberi locali "+dbName);
            }
            };
            
            // Make a request to add our newItem object to the object store
            let request2 = objectStore.get("");



            request2.onsuccess = function(event) {
            // Clear the form, ready for adding the next entry
            console.log('Richiesta recupero OK.');
            console.log(event)
            };

            // Report on the success of the transaction completing, when everything is done
            transaction.oncomplete = function() {

            // update the display of data to show the newly added item, by running displayData() again.
            //displayData();
            };

            transaction.onerror = function() {
            console.log('Recupero fallito');
            };   
        };  
    }, 
    testGET(){
        this.axios({
        method: 'get',
        url: 'auth/test'
        }).then((response) => {

            console.log(response.data)
        });
    },
    addNewTree(){
    this.gotoToPage("/addNewTree")
    serverBus.$emit("updateMapPoint");
    },
    subMenuAlbero(){
    //
    },
    gotoToPage(page){
    if (page!==this.$route.path) this.$router.push(page);
    },
    addTree(){
    this.gotoToPage("/addNT1")
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    }  


    },

}

async function putLocalSingleData(dataId,data,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    set(dataId,data,customStore)
}
async function getLocalSingleData(objId,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    get(objId,customStore).then((val) => {
        console.log(val);
        return val
})}
async function updateSingleData(objId,newData,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    update(objId, newData,customStore);
}
async function getAllEntries(dbName,storeName){
    const customStore = createStore(dbName, storeName);
    entries(customStore).then((entries) => console.log(entries));
}
async function delSingleData(objId,dbName,storeName){
    const customStore = createStore(dbName, storeName);
    console.log("DELETE:"+objId)
    del(objId,customStore);
}

</script>